import React from "react";
import Card from "../../../UI/Card";
import otps from "../../../../Assets/otps.png";
import Input from "../OpenAcct/Input";
import { Link } from "react-router-dom";

const Otp = () => {
  return (
    <section className="flex flex-col md:justify-center items-center overflow-hidden">
      <Card className="w-full md:w-[32rem] h-screen shadow-lg">
        <p className="text-base font-semibold text-[#1D1D1F] leading-[20px] text-center pt-[3rem] lg:pt-[2rem]">
          Verify OTP
        </p>
        <div className="flex justify-center  mt-[8rem] md:mt-20 lg:mt-0">
          <img
            src={otps}
            alt=""
            className="object-cover w-[9rem] h-[9rem] md:mt-12"
          />
        </div>
        <h3 className="text-center mt-5 font-medium text-lg leading-[2rem]">
          Enter 6-digit code sent to
        </h3>
        <p className="text-center mt-0 font-semibold text-lg leading-[2rem]">
          Ayobalogun@gmail.com
        </p>
        <form>
          <div className="pt-[2.01rem] flex justify-center gap-3 ">
            <Input
              type="text"
              className="md:w-[4rem] w-[42px] h-[2.75rem] md:h-[3.75rem] pl-5 border-2 border-[#E8E9EB] rounded-md focus:outline-blue-400 mb-[2rem]"
            />

            <Input
              type="text"
              className={`md:w-[4rem] w-[42px]  h-[2.75rem] md:h-[3.75rem] pl-5 border-2 border-[#E8E9EB] rounded-md focus:outline-blue-400
              `}
            />
            <Input
              type="text"
              className={`md:w-[4rem] w-[42px]  h-[2.75rem] md:h-[3.75rem] pl-5 border-2 border-[#E8E9EB] rounded-md focus:outline-blue-400
              `}
            />
            <Input
              type="text"
              className={`md:w-[4rem] w-[42px]  h-[2.75rem] md:h-[3.75rem] pl-5 border-2 border-[#E8E9EB] rounded-md focus:outline-blue-400
              `}
            />
            <Input
              type="text"
              className={`md:w-[4rem] w-[42px]  h-[2.75rem] md:h-[3.75rem] pl-5 border-2 border-[#E8E9EB] rounded-md focus:outline-blue-400
              `}
            />
            <Input
              type="text"
              className={`md:w-[4rem] w-[42px]  h-[2.75rem] md:h-[3.75rem] pl-5 border-2 border-[#E8E9EB] rounded-md focus:outline-blue-400
              `}
            />
          </div>
        </form>
        <p className="text-center font-medium mt-4">00:43s</p>
        <h5 className="text-center font-medium mt-3">
          Didn’t get an otp? <span className="text-[#017A59]">Click here</span>
        </h5>
        <div className="flex justify-center px-7">
          <button className=" md:mt-44 xl:mt-24 mt-32 bg-[#017A59] text-white rounded-md w-full h-[48px]">
            <Link to="/successful"> Verify OTP</Link>
          </button>
        </div>
      </Card>
    </section>
  );
};

export default Otp;
