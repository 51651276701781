import { useParams } from "react-router-dom";
import Main2 from "./Careers/Main2";
import Main1 from "./AboutUs/Main1";
// import Main3 from "./Blog/Main3";
import Individual from "../../Individual";
import { useEffect } from "react";

const DynamicCompany = () => {
  const { pageName } = useParams();

  useEffect(() => {
    if (pageName === "3") {
      window.location.href = "https://medium.com/@feedxpay";
    }
  }, [pageName]);

  if (pageName === "3") {
    return null;
  }

  if (pageName === "aboutus") {
    return <Main1 />;
  } else if (pageName === "career") {
    return <Main2 />;
  } else {
    return <Individual />;
  }
};
export default DynamicCompany;
