import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-[#017A59] bg-opacity-20 z-40"
      onClick={props.onClose}
    />
  );
};

const Overlay = (props) => {
  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xl:w-1/2 w-[85%] md:w-[80%] md:h-[16rem] lg:w-3/5 h-[16rem] lg:h-60 bg-white p-4 sm:p-5 rounded-xl shadow-2xl z-40">
      <div>{props.children}</div>
    </div>
  );
};
const portalElements = document.getElementById("overlays");
const SuccessModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElements
      )}
      {ReactDOM.createPortal(
        <Overlay>{props.children}</Overlay>,
        portalElements
      )}
    </>
  );
};

export default SuccessModal;
