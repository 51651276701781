import React from "react";
import Card from "../../../UI/Card";

const Benefits = () => {
  const benefits = [
    {
      id: 1,
      num: "01",
      name: "Competitive Salary",
      text: "Join our team and be rewarded with more than just a paycheck – experience a competitive salary that reflects your skills and contributions. We believe in recognizing and valuing the talents.",
    },
    {
      id: 2,
      num: "02",
      name: "Paid Leave",
      text: "Balance is not just a concept; it's a reality in our work culture. Enjoy the benefits of paid leave that go beyond mere time off. Recharge, rejuvenate, and return with a fresh perspective.",
    },
    {
      id: 3,
      num: "03",
      name: "Allowances",
      text: "Beyond your competitive salary, we recognize the importance of additional support.  Whether it's for professional development, technology needs, or other work-related expenses.",
    },
    {
      id: 4,
      num: "04",
      name: "Learning and Development",
      text: " Fuel your career growth with our commitment to learning and development. We provide a platform where your professional evolution is not just encouraged but actively supported.",
    },
  ];
  return (
    <section className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto flex flex-col sm:flex-row justify-center lg:space-x-[3.94rem] md:space-x-[2rem] pro:space-x-[4rem]">
      <div className="flex flex-col justify-center pt-[0.5rem] ">
        <h1 className="uppercase text-sm text-[#017A59] md:text-base lg:text-[1.5rem] pt-[3.13rem] sm:pt-[6.25rem] font-semibold leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
          Our Benefits
        </h1>

        <p className="text-[#1D1D1F] pt-[0.63rem] text-sm sm:pt-[2rem] md:text-xs lg:text-[1rem] xl:text-[1.5rem] font-medium leading-normal lg:leading-[2.3125rem] tracking-[-0.03rem]">
          There are great benefits to <br className="hidden sm:block" />
          being a part of Feedxpay team. <br className="hidden sm:block" />
          These serve as motivations <br className="hidden sm:block" />
          at work and while on leave.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[1.88rem] pt-[3.13rem] sm:pt-[12.06rem] pb-[4.38rem] sm:pb-[7rem] place-content-start justify-start text-start items-start">
        {benefits.map((item) => (
          <Card
            key={item.id}
            className="xl:w-[23.3125rem] w-[21.375rem] h-[14.8875rem] md:w-[13.3125rem] md:h-[16.9125rem] lg:w-[19.3125rem] lg:h-[19.5125rem] xl:h-[20.5125rem] relative bg-[#EBFFF5]/40 sm:bg-white bg-opacity-40  rounded-xl shade p-[1.25rem] lg:p-[1.2rem] xl:p-[1.82rem] md:p-[0.75rem]"
          >
            {" "}
            <p className="text-[#017A59]  text-[1.375rem] text-opacity-40 md:text-lg lg:text-[1.875rem] xl:text-[2.875rem] font-bold  lg:leading-normal xl:leading-[2.375rem] tracking-[-0.0575rem]">
              {item.num}
            </p>
            <h1 className="sm:pb-[1rem] pt-[1rem] pb-[0.75rem] md:pb-[0.6rem] text-[1.125rem] md:text-base text-[#017A59] md:pt-[0.6rem] lg:pt-[1.5rem] lg:text-[1.125rem] xl:text-[1.375rem] font-semibold leading-normal lg:leading-[2rem] ">
              {item.name}
            </h1>
            <p className="text-[#1D1D1F] text-sm md:text-[13px] lg:text-base xl:text-[1.125rem] font-medium leading-normal lg:leading-[1.5rem]">
              {item.text}
            </p>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Benefits;
