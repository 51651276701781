import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-[#017A59] bg-opacity-20 z-40"
      onClick={props.onClose}
    />
  );
};

const Overlay = (props) => {
  return (
    <div className="fixed iphone7:top-[30.5vh] iphone7:h-[29.5rem] top-[24vh] iphone14:top-[22vh] iphone12:top-[24vh] left-1/2 transform md:top-[22.5vh] translate-x-[-50%] xl:w-[34%] w-[90%] md:w-[73%] md:h-[29.7rem] lg:w-[43%] h-[28.3rem] lg:h-[33.2rem] bg-white p-[0.8rem] sm:p-[1rem] rounded-xl shadow-2xl z-40">
      <div>{props.children}</div>
    </div>
  );
};
const portalElements = document.getElementById("overlays");
const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElements
      )}
      {ReactDOM.createPortal(
        <Overlay>{props.children}</Overlay>,
        portalElements
      )}
    </>
  );
};

export default Modal;
