import React from "react";
import Tofunmi from "../../../../../Assets/Tofunmi.png";
import faruk from "../../../../../Assets/faruk.png";
import yellow from "../../../../../Assets/yellow.png";
import Card from "../../../../UI/Card";

const Team = () => {
  return (
    <section className="xl:max-w-[73rem] pb-16 lg:pb-20 pro:max-w-[52rem] max-w-[21.5rem] md:max-w-[45rem] lg:max-w-[57rem] mx-auto">
      <h2 className=" uppercase pt-[3.12rem] text-sm sm:pt-[6.25rem] text-[#017A59] text-[1.5rem] font-semibold leading-[2.3125rem] tracking-[-0.03rem]">
        Our Team
      </h2>
      <div className="flex flex-col sm:flex-row sm:justify-between">
        <p className="pt-[1.28rem] text-[1.125rem] text-[#1D1D1F] lg:text-[1.7rem] xl:text-[2rem] font-bold md:leading-normal lg:leading-[3rem]">
          The amazing people <br className="hidden sm:block" />
          doing the work
        </p>
        <div>
          <p className="text-[#1D1D1F] block sm:hidden pt-[1.25rem] text-base  font-medium leading-[1.5rem] tracking-[-0.02125rem]">
            We are a team of engineers, entrepreneurs, designers and artists
            working together with a singular mission of building a new and
            better future of successful and permission-less entrepreneurship for
            everyone. <br />
          </p>
          <p className="mt-[1rem] text-[#1D1D1F] block sm:hidden pt-[1.25rem] text-base  font-medium leading-[1.5rem] tracking-[-0.02125rem]">
            If you run a business or ever decide to do so, we are here to
            support you.
          </p>
          <p className="text-[#1D1D1F] hidden sm:inline-block pt-[1.25rem] sm:pt-0 text-base md:text-sm lg:text-[1.0625rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
            At FeedXPay, our success is built on the foundation of a dedicated
            and
            <br className="hidden sm:block" /> diverse team of professionals who
            share a passion for innovation,
            <br className="hidden sm:block" /> excellence, and a commitment to
            redefining the future of financial
            <br className="hidden sm:block" /> services.
          </p>
          {/* <p className="pt-[1rem] text-[#1D1D1F] text-[1.0625rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
            {" "}
            If you run a business or ever decide to do so, we are here to
            support you.
          </p> */}
        </div>
      </div>
      {/* <div className="flex space-x-[0.75rem]  justify-center pt-[4.88rem] sm:pb-[11.87rem] pb-[7.87rem] overflow-hidden">
        <div className="relative">
          <img
            src={faruk}
            alt=""
            className="w-[13.75rem] h-[13.75rem] md:w-[25.142rem] xl:w-[28.652rem] lg:w-[22.142rem] xl:h-[30.125rem] lg:h-[25.125rem]  md:h-[22.125rem]"
          />
          <Card className="bg-[#017A59] sm:px-[1rem] px-[0.65rem] w-[10.3rem] py-[0.69rem] md:w-[15.772rem] xl:w-[22.952rem] lg:w-[17.742rem] h-[4.125rem] sm:h-[5.125rem] absolute top-[10rem] md:top-[17rem] xl:top-[25rem] lg:top-[20rem] left-0 rounded-br-xl rounded-bl-xl">
            <h5 className="text-white capitalize text-xs md:text-base lg:text-[1.5rem] font-semibold leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
              Rufai Faruk
            </h5>
            <p className="text-[1.125rem] text-white text-xs md:text-base font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              Chief Executive officer
            </p>
          </Card>
        </div>
        <div className="relative">
          <img
            src={Tofunmi}
            alt=""
            className="w-[13.75rem] h-[13.75rem] md:w-[25.142rem] xl:w-[28.652rem] lg:w-[22.142rem] xl:h-[30.125rem] lg:h-[25.125rem]  md:h-[22.125rem]"
          />
          <Card className="bg-[#017A59] sm:px-[1rem] px-[0.65rem] py-[0.69rem] w-[10.3rem] md:w-[15.842rem] xl:w-[22.952rem] lg:w-[17.742rem] h-[4.125rem] sm:h-[5.125rem] absolute top-[10rem] lg:top-[20rem] xl:top-[25rem] md:top-[17rem] left-0 rounded-br-xl rounded-bl-xl">
            <h5 className="text-white capitalize text-xs md:text-base lg:text-[1.5rem] font-semibold leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
              Okedeji Tofunmi
            </h5>
            <p className="lg:text-[1.125rem] text-white text-xs md:text-base font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              Chief Technology officer
            </p>
          </Card>
        </div>
        <div className="relative">
          <img
            src={yellow}
            alt=""
            className="hidden sm:block md:w-[15.142rem] xl:w-[27.652rem] lg:w-[22.142rem] xl:h-[30.125rem] lg:h-[25.125rem] md:h-[22.125rem]"
          />
          <Card className="bg-[#017A59] hidden sm:block px-[1rem] py-[0.69rem] md:w-[13.842rem] xl:w-[22.952rem] lg:w-[17.742rem] h-[5.125rem] absolute lg:top-[20rem] md:top-[17rem] xl:top-[25rem] left-0 rounded-br-xl rounded-bl-xl">
            <h5 className="text-white capitalize md:text-base lg:text-[1.5rem] font-semibold leading-[2.3125rem] tracking-[-0.03rem]">
              Akin Fayokon
            </h5>
            <p className="lg:text-[1.125rem] md:text-base text-white font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              Chief information security officer
            </p>
          </Card>
        </div>
      </div> */}
    </section>
  );
};

export default Team;
