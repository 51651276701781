import React from "react";
import Card from "../../../UI/Card";

const Steps = () => {
  const steps = [
    {
      id: 1,
      steps: "01",
      text: "Download the Feedxpay App and deposit enough amount of money into your account.",
    },
    {
      id: 2,
      steps: "02",
      text: "Choose your preferred savings plan and complete the important informations required.",
    },
    {
      id: 3,
      steps: "03",
      text: "Sit back and watch your money grow over the period of saving. Nothing beats this feeling.",
    },
  ];
  return (
    <section
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[60rem] mx-auto"
    >
      <h1 className="md:text-[2.575rem] text-[1.275rem] lg:text-[2.875rem] pt-[3.12rem] sm:pt-[6.25rem] text-center text-[#1D1D1F] font-bold leading-normal md:leading-[3.875rem] tracking-[-0.0575rem]">
        Get Started in{" "}
        <span className="text-[#017A59]">three simple steps</span>
      </h1>

      <p className="text-[#8A9099] text-sm text-center pt-[0.5rem] lg:text-[1.375rem] font-medium leading-normal sm:leading-[2.125rem]">
        Here are three easy steps to get your savings journey started
      </p>

      <div className="flex flex-col gap-[1.5rem] sm:flex-row sm:gap-[1.62rem] pt-[2.5rem] sm:pt-[6.44rem] pb-[4.37rem] sm:pb-[6.25rem]">
        {steps.map((step) => (
          <Card
            key={step.id}
            className="sm:w-[27.625rem] w-[21.375rem] p-[1.5rem] contain md:p-[1rem] lg:p-[2rem] ease-in-out transition-all h-[11.1875rem] duration-300 text-[#ffffff] md:h-[13.7rem] lg:h-[16.5rem] border-2 border-[#017A59] rounded-lg hover:shadow-xl"
          >
            <h2 className="sm:text-[2.875rem] text-[1.375rem] text1 font-bold leading-[2.1875rem] sm:leading-[3.875rem] tracking-[-0.0575rem]">
              {step.steps}
            </h2>

            <p className=" text2 sm:pt-[2rem] pt-[1.5rem] text-sm md:text-sm lg:text-[1.1rem] xl:text-[1.375rem] font-medium leading-normal md:leading-[1.875rem] tracking-[-0.0275rem]">
              {step.text}
            </p>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Steps;
