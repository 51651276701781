import React from "react";
import ceo2 from "../../../../Assets/ceo2.png";
import cto2 from "../../../../Assets/cto2.png";

const Hear = () => {
  return (
    <section className="bgs sm:h-[61.3125rem] h-[68.3125rem]">
      <div className="xl:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto py-[4.19rem] sm:py-[6.25rem]">
        <h1 className="text-[#1D1D1F] text-center text-[1.375rem] sm:text-[2rem] font-bold leading-[2.1875rem] sm:leading-[3rem] capitalize">
          our <span className="text-[#017A59] uppercase">cto & ceo</span>{" "}
        </h1>

        <p className="sm:leading-[2.125rem] leading-normal text-sm text-[#8A9099] text-center pt-[0.5rem] font-medium lg:text-[1.375rem]">
          know about Feedxpay from the big horns
        </p>
        <div className="sm:pt-[4.81rem] pt-[2.5rem] flex flex-col space-y-[2.5rem] sm:space-y-0 sm:flex-row xl:space-x-[6.12rem] md:space-x-[1.9rem] pro:space-x-[2.12rem] lg:space-x-[3.12rem] justify-center">
          <div>
            <img
              src={ceo2}
              alt=""
              className="object-cover w-[21.375rem] rounded-lg h-[21.0625rem] md:w-[22.5625rem] pro:w-[24.5625rem] xl:w-[35.5625rem] lg:w-[27.5625rem] sm:h-[33.1875rem]"
            />

            <p className="text-[#017A59] text-center pt-[1rem] sm:pt-[2.5rem] text-base sm:text-[1.5rem] font-semibold leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
              Rufai Faruk
            </p>
            <p className="uppercase pt-[0.5rem] text-base text-center text-[#1D1D1F] sm:text-[1.375rem] font-semibold sm:font-medium leading-[1.875rem] tracking-[-0.0275rem]">
              Ceo
            </p>
          </div>
          <div>
            <img
              src={cto2}
              alt=""
              className="object-cover w-[21.375rem] rounded-lg h-[21.0625rem] md:w-[22.5625rem] pro:w-[24.5625rem] xl:w-[35.5625rem] lg:w-[27.5625rem] sm:h-[33.1875rem]"
            />
            <p className="text-[#017A59] text-center pt-[1.5rem] sm:pt-[2.5rem] text-base sm:text-[1.5rem] font-semibold leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
              Okedeji Tofunmi
            </p>
            <p className="uppercase text-center pt-[0.5rem] text-[#1D1D1F] text-base sm:text-[1.375rem] font-semibold sm:font-medium leading-[1.875rem] tracking-[-0.0275rem]">
              Cto
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hear;
