import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home1 from "../Routes/MakePayment/Home1";
import Home3 from "../Routes/Savings/Home3";
import Home2 from "../Routes/VirtualCards/Home2";
import Home4 from "../Routes/Investments/Home4";
// import App from "../../../App";
import DynamicHome from "../Routes/DynamicHome";
import DynamicCompany from "../Routes/DynamicCompany";
// import Main3 from "../Routes/Blog/Main3";
import Main1 from "../Routes/AboutUs/Main1";
import Main2 from "../Routes/Careers/Main2";
import DynamicSupport from "../Routes/DynamicSupport";
import Support1 from "../Routes/FAQs/Support1";
import Support2 from "../Routes/Partner/Support2";
import Support4 from "../Routes/ContactSales/Support4";
import SignIn from "../Routes/SignIn/SignIn";
import OpenAccount from "../Routes/OpenAcct/OpenAccount";
import Business from "../../Business/Business";
import Individual from "../../Individual";
import BDynamic2 from "../../Business/BDynamic2";
import Company1 from "../../Business/Routers/AboutUs2/AboutUs2/Company1";
import Company2 from "../../Business/Routers/Careers/Company2";
import BDynamics3 from "../../Business/BDynamic3";
import Supports1 from "../../Business/Routers/FAQs/Supports1";
import Supports2 from "../../Business/Routers/Partner/Supports2";
import Supports from "../../Business/Routers/Contact-Sales/Supports";
import PrivacyPolicy from "../../Business/PrivacyPolicy";
import Policy from "../Policy";
import Support3 from "../../Business/Routers/Support/Support3";
import Support5 from "../Routes/Support/Support5";
import Delete from "../Routes/SignIn/Delete";
import Otp from "../Routes/SignIn/Otp";

import Successful from "../Routes/SignIn/Successful";
import Stay from "../Routes/SignIn/Stay";

const RouterComponent = () => {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/business" />} /> */}
          {/* <Route path="/individual" element={<Individual />} /> */}
          {/* <Route path="/home/:id" element={<DynamicHome />} /> */}
          <Route path="/" element={<Business />} />
          <Route path="/support/:id" element={<DynamicSupport />} />
          <Route path="/main/pageName`" element={<DynamicCompany />} />
          <Route path="/company/pageName" element={<BDynamic2 />} />
          <Route path="/supports/pageName" element={<BDynamics3 />} />
          <Route path="/payment" element={<Home1 />} />
          <Route path="/virtualcards" element={<Home2 />} />
          <Route path="/savings" element={<Home3 />} />
          <Route path="/investments" element={<Home4 />} />
          <Route path="/individual/aboutus" element={<Main1 />} />
          <Route path="/individual/career" element={<Main2 />} />
          <Route path="/blogs/:mainId" element={<DynamicCompany />} />
          <Route path="/individual/faqs" element={<Support1 />} />
          <Route path="/individual/partners" element={<Support2 />} />
          <Route path="/individual/contactSale" element={<Support4 />} />
          <Route path="/openAcct" element={<OpenAccount />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/business" element={<Business />} />
          <Route path="/aboutUs" element={<Company1 />} />
          <Route path="/contactSales" element={<Supports />} />
          <Route path="/faq" element={<Supports1 />} />
          <Route path="/careers" element={<Company2 />} />
          <Route path="/partner" element={<Supports2 />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/support" element={<Support3 />} />
          <Route path="/individual/support" element={<Support5 />} />
          <Route path="/deleteAccount" element={<SignIn />} />
          <Route path="/delete" element={<Delete />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/successful" element={<Successful />} />
          <Route path="/stay" element={<Stay />} />


          <Route path="*" element={<Navigate to="/business" />} />
        </Routes>
      </Router>
    </>
  );
};

export default RouterComponent;
