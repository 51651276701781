import React, { useState } from "react";
import Nav from "../../NavBar/Nav";
import pngwing1 from "../../../../Assets/pngwing1.png";
import pngwing2 from "../../../../Assets/pngwing2.png";
import VirtualCards from "../../../../Assets/VirtualCards.png";
import Products from "../../NavBar/Products";
import Support from "../../NavBar/Support";
import Company from "../../NavBar/Company";

const Header2 = () => {
  const [modal, setModal] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleModal = () => {
    setOpenModals(true);
  };

  const handleSupportModal = () => {
    setSupportModal(true);
  };

  return (
    <>
      <Nav
        onClick={openModal}
        onOpen={handleModal}
        onSet={handleSupportModal}
      />
      {modal && <Products onHideModal={() => setModal(false)} />}
      {openModals && <Company onHideModals={() => setOpenModals(false)} />}
      {supportModal && (
        <Support onHideSupportModal={() => setSupportModal(false)} />
      )}

      <section className="background xl:h-[55.06rem] lg:h-[57.06rem] h-[59.06rem]">
        <header
          className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
            lg:max-w-[60rem] mx-auto flex flex-col-reverse sm:flex-row sm:justify-between pt-[9.5rem]"
        >
          <div>
            <h1 className="text-[#1D1D1F] md:pt-[5.25rem] lg:pt-[8.25rem] pt-[1.53rem] text-[1.375rem] md:text-[1.575rem] leading-[2.1875rem] lg:text-[2.3rem] xl:text-[2.875rem] font-bold sm:leading-[3.875rem] tracking-[-0.0575rem]">
              Experience{" "}
              <span className="text-[#017A59]">Financial Freedom</span>
              <br />
              get a card that works
            </h1>

            <p className="text-[#8A9099] text-base pt-[0.75rem] sm:pt-[1rem] md:text-sm leading-normal lg:text-base xl:text-[1.375rem] font-medium lg:leading-[2.125rem]">
              Bridge the gap between convenience and security,
              <br className="hidden sm:block" /> get a card you can trust to
              make your online <br className="hidden sm:block" />
              transactions seemlessly.
            </p>
            <div className="flex xl:pt-[5.88rem] pt-[4.28rem] md:pt-[2.5rem] lg:pt-[3.88rem] gap-[2rem]">
              <img
                src={pngwing1}
                alt=""
                className="object-contain w-[6.5rem] h-[2.4375rem] md:w-[6.3125rem] lg:w-[10.3125rem] xl:w-[14.375rem] sm:h-[5.375rem]"
              />
              <img
                src={pngwing2}
                alt=""
                className="xl:w-[14.3125rem] w-[6.5rem] h-[1.9375rem] mt-[0.25rem] md:w-[6.3125rem] lg:w-[10.3125rem] object-contain sm:h-[4.25rem] sm:mt-[0.56rem]"
              />
            </div>
          </div>
          <div className="sm:pt-[3.06rem] pt-[0.36rem]">
            <img
              src={VirtualCards}
              alt=""
              className="object-cover lg:w-[31.12513rem] lg:h-[39.625rem] md:w-[21.12514rem] md:h-[25.12514rem] w-[27.80156rem] h-[28.08463rem]"
            />
          </div>
        </header>
      </section>
    </>
  );
};

export default Header2;
