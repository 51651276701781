import React, { useRef, useState } from "react";
import Card from "../../../UI/Card";
import Input from "../../../Layout/Routes/OpenAcct/Input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "../../../Success";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ComplaintsForm = () => {
  const [modalSuccess, setModalSuccess] = useState(false);
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const emailRef = useRef(null);
  const contentRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      firstnameRef.current.value === "" ||
      lastnameRef.current.value === "" ||
      emailRef.current.value === "" ||
      !emailRef.current.value.includes("@") ||
      contentRef.current.value === ""
    ) {
      toast.error("Please fill out all fields correctly", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        theme: "dark",
      });
      console.log("toast");
      return;
    }

    try {
      const formData = {
        first_name: firstnameRef.current.value,
        last_name: lastnameRef.current.value,
        email: emailRef.current.value,
        message: contentRef.current.value,
        message_type: "ContactSupport",
      };

      const response = await fetch("https://api.feedxpay.com/v1/site/support", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        console.log("success");
        setModalSuccess(true);
      } else {
        throw new Error("Something went wrong");
      }

      firstnameRef.current.value = "";
      lastnameRef.current.value = "";
      emailRef.current.value = "";
      contentRef.current.value = "";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="bgs sm:h-[80.25rem] h-[61.5rem] sm:pt-[9.38rem] pt-[3.17rem] flex flex-col items-center">
      <ToastContainer />
      <Card className="lg:w-[50.125rem] md:w-[40.125rem] w-[22.125rem] sm:h-[64.625rem] h-[58.625rem] sm:bg-white shades rounded-2xl pt-[4.81rem]">
        <h2 className="text-[#1D1D1F] text-[1.125rem] md:text-[1.5rem] lg:text-[2rem] leading-normal sm:leading-[3rem] font-bold text-center">
          Write a message to our support team
        </h2>

        <p className="pt-[0.75rem] text-sm  text-center text-[#1D1D1F] md:text-sm lg:text-[1.125rem] font-medium leading-[1.5rem]">
          Kindly drop your message and be rest assured that{" "}
          <br className="hidden lg:block" />
          we will get back to you as quick as possible
        </p>
        <form
          onSubmit={handleSubmit}
          className="sm:pt-[7.75rem] relative pt-[2.81rem] flex flex-col items-center"
        >
          <div className="flex flex-col sm:flex-row sm:gap-[2rem] sm:pb-[2.5rem]">
            <Input
              type="text"
              ref={firstnameRef}
              label="First Name"
              className="lg:w-[18.125rem] mb-[1.5rem] sm:mb-0 w-[21.375rem] md:w-[15.125rem] pl-5 sm:h-[3.75rem] h-[3rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
            />
            <Input
              type="text"
              label="Last Name"
              ref={lastnameRef}
              className="lg:w-[18.125rem] w-[21.375rem] mb-[1.5rem] sm:mb-0 md:w-[15.125rem] pl-5 sm:h-[3.75rem] h-[3rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
            />
          </div>
          <div className="flex gap-[2rem] sm:pb-[2.5rem] pb-[1.5rem]">
            <Input
              type="text"
              ref={emailRef}
              label="Email Address"
              className="lg:w-[38.25rem] w-[21.375rem] md:w-[32.25rem] pl-5 sm:h-[3.75rem] h-[3rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
            />
          </div>
          <div>
            <Card className="lg:w-[38.25rem] w-[21.375rem] md:w-[32.25rem] bg-white flex space-x-5 px-5 py-[0.62rem] h-[3.75rem] border-t-2 border-r-2 border-l-2 border-[#E8E9EB] rounded-sm">
              <p className="mt-2 text-[#1D1D1F] text-sm sm:text-[1.125rem] font-semibold tracking-[-0.0225rem] leading-[1.5rem]">
                To :{" "}
              </p>
              <p className="sm:w-[12.5625rem] w-[5.3125rem] sm:h-[2.375rem] bg-[#F5F5F7] text-[#1D1D1F] text-sm sm:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem] px-2 sm:px-5 py-2 rounded-md">
                Feedxpay
              </p>
            </Card>
          </div>
          <div className=" pb-[2.5rem]">
            <textarea
              type="text"
              ref={contentRef}
              className="lg:w-[38.25rem] w-[21.375rem] md:w-[32.25rem] pl-5 sm:h-[18.91rem] h-[14.8125rem] border-2 border-[#E8E9EB] rounded-b-xl  focus:outline-blue-400"
            />
          </div>
          <button
            type="submit"
            className="hidden sm:w-[10.125rem] md:flex justify-center items-center w-[4.375rem] sm:text-[1.125rem] text-base left-7 top-[47rem] font-medium leading-[1.5rem] tracking-[-0.0225rem] h-[1.975rem] absolute md:left-24 lg:left-28 md:top-[40rem] sm:h-[3.6875rem] bg-[#017A59] rounded-md text-white"
          >
            Send <span className="hidden sm:flex sm:ml-1">Message</span>
          </button>
          <button
            type="submit"
            className="flex absolute top-[36rem] left-7 items-center justify-center sm:w-[10.125rem] md:hidden w-[4.375rem] sm:text-[1.125rem] text-base font-medium leading-[1.5rem] tracking-[-0.0225rem] h-[1.975rem] sm:h-[3.6875rem] bg-[#017A59] rounded-md text-white"
          >
            Send
          </button>
        </form>
      </Card>
      {modalSuccess && <Success onClose={() => setModalSuccess(false)} />}
    </section>
  );
};

export default ComplaintsForm;
