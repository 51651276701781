import React from "react";
import Experience2 from "../../../../Assets/Experience2.png";

const Experience = () => {
  return (
    <section className="background lg:h-[77.125rem] md:h-[65.125rem] h-[32.125rem]">
      <div className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto lg:pt-[6.25rem] md:pt-[4.25rem] pt-[3.38rem] pb-[10.81rem] flex flex-col-reverse sm:flex-col justify-center items-center">
        <h1 className="text-[#017A59] hidden sm:block md:text-[2.375rem] lg:text-[2.875rem] font-bold leading-[3.875rem] tracking-[-0.0575rem] pb-[1.25rem]">
          Feedxpay Experience
        </h1>
        <p className="text-[#1D1D1F] text-sm pb-[3rem] sm:pb-[6.25rem] xl:text-[1.4rem] lg:text-[1.125rem] md:text-[0.9rem] text-center font-medium leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
          Looking for a place to explore your knowledge? Come and join a team of
          problem solvers,
          <br className="hidden sm:block" />
          artists, entrepreneurs, engineers and designers, customer service
          providers and creators in
          <br className="hidden sm:block" />
          their best form, doing their best work in creating a new bank for
          transacting business and
          <br className="hidden sm:block" />
          making payments easily and in quick time.
        </p>
        <h1 className="text-[#017A59] text-[1.375rem] md:text-[2.375rem] block sm:hidden lg:text-[2.875rem] font-bold leading-[2.1875rem] pt-[1.5rem] tracking-[-0.0575rem] pb-[1.25rem]">
          Feedxpay Experience
        </h1>

        <div>
          <img
            src={Experience2}
            alt=""
            className="lg:h-[42.4375rem]  md:w-[39rem] md:h-[32.4375rem] lg:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Experience;
