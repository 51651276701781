import React, { useEffect } from "react";
import Headers from "./Headers";
import Join from "../../Join/Join";
import Footer from "../../Footer/Footer";
import ComplaintsForm from "./ComplaintsForm";

const Support5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <>
      <Headers />
      <ComplaintsForm />
      <Join />
      <Footer />
    </>
  );
};

export default Support5;
