import { useParams } from "react-router-dom";
import Supports1 from "./Routers/FAQs/Supports1";
import Supports2 from "./Routers/Partner/Supports2";
import Supports from "./Routers/Contact-Sales/Supports";
import Business from "./Business";

const BDynamics3 = () => {
  const { pageName } = useParams();

  if (pageName === "1") {
    return <Supports1 />;
  } else if (pageName === "2") {
    return <Supports2 />;
  } else if (pageName === "4") {
    return <Supports />;
  } else {
    return <Business />;
  }
};
export default BDynamics3;
