import React from "react";
import BModal3 from "../../UI/BModal3";
import { Link } from "react-router-dom";
import Support1 from "../../../Assets/Business/Support1.png";
import Support2 from "../../../Assets/Business/Support2.png";
import Support3 from "../../../Assets/Business/Support3.png";
import Support4 from "../../../Assets/Business/Support4.png";

const Support = (props) => {
  const support = [
    {
      id: 1,
      name: "FAQs",
      img: Support1,
      text: "We answered some of your questions.",
      route: "/faq",
    },
    {
      id: 2,
      name: "Become a partner",
      img: Support2,
      text: "Our support team got your covered",
      route: "/partner",
    },
    {
      id: 3,
      name: "Community",
      img: Support3,
      text: "Join our community of great people",
      externalLink: "https://t.me/feedxpay",
    },
    {
      id: 4,
      name: "Contact Sales",
      img: Support4,
      text: "Be a part of the growth, join the movement",
      route: "/contactSales",
    },
    {
      id: 5,
      name: "Support",
      img: Support4,
      text: "reachout to our support team",
      route: "/support",
    },
  ];

  return (
    <BModal3 onClose={props.onHideSupportsModal}>
      <div className="lg:px-[2.19rem] px-[1.5rem] xl:pt-[2.12rem] lg:pt-[1.8rem] pt-[2rem] ">
        <h1 className="text-[#017A59] text-[1.125rem] font-semibold leading-normal tracking-[-0.0225rem] pb-[1.5rem] border-b-2 border-[#E8E9EB]">
          Support
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3">
          {support.map((calls, index) =>
            calls.route ? (
              <Link to={calls.route} key={calls.id}>
                <div className="flex pt-[2rem] gap-[1rem]">
                  <img
                    src={calls.img}
                    alt=""
                    className="h-[2.9375rem] w-[2.9375rem]"
                  />
                  <div className="text-[#1D1D1F] lg:text-base text-xs xl:text-[1.125rem] font-semibold leading-normal tracking-[-0.0225rem]">
                    <h1 className="xl:pt-[0.3rem]">{calls.name}</h1>
                    <p
                      className={` ${
                        index === 3 ? "lg:w-[18rem]" : ""
                      }  text-[#8A9099] text-xs lg:text-sm xl:text-[1.025rem] font-medium leading-normal`}
                    >
                      {calls.text}
                    </p>
                  </div>
                </div>
              </Link>
            ) : (
              <a
                href={calls.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                key={calls.id}
              >
                <div className="flex pt-[2rem] gap-[1rem]">
                  <img
                    src={calls.img}
                    alt=""
                    className="h-[2.9375rem] w-[2.9375rem]"
                  />
                  <div className="text-[#1D1D1F] lg:text-base text-xs xl:text-[1.125rem] font-semibold leading-normal tracking-[-0.0225rem]">
                    <h1 className="xl:pt-[0.3rem]">{calls.name}</h1>
                    <p
                      className={` ${
                        index === 3 ? "lg:w-[22rem]" : ""
                      } text-[#8A9099] text-xs lg:text-sm xl:text-[1.025rem] font-medium leading-normal`}
                    >
                      {calls.text}
                    </p>
                  </div>
                </div>
              </a>
            )
          )}
        </div>
      </div>
    </BModal3>
  );
};

export default Support;
