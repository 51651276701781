import React, { useState } from "react";
import Card from "../UI/Card";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const Questions = () => {
  const questions = [
    {
      id: 1,
      text: "How does FeedxPay simplify cross-border transactions for businesses?",
      answer:
        "FeedxPay simplifies cross-border transactions for businesses by providing a streamlined payment process, eliminating hidden fees, and offering real-time exchange rates. Our platform ensures swift and secure international transfers, making it easy for businesses to manage their global payments efficiently.",
    },
    {
      id: 2,
      text: "What are the benefits of opening a multi-currency account with FeedxPay?",
      answer:
        "Opening a multi-currency account with FeedxPay offers numerous benefits. It allows you to hold and manage multiple currencies in one place, enabling cost-effective international transactions and reducing currency conversion fees. With our multi-currency accounts, businesses can seamlessly transact across borders without the complexities of dealing with different currencies.",
    },
    {
      id: 3,
      text: "How secure and reliable are online money transfers with FeedxPay?",
      answer:
        "Online money transfers with FeedxPay are highly secure and reliable. We employ cutting-edge encryption technology to safeguard your funds and protect your transactions from unauthorized access. Our platform is built with robust security measures, ensuring a safe and seamless experience for all your online financial activities.",
    },
    {
      id: 4,
      text: "Why choose FeedxPay for fast and cost-effective international money transfers?",
      answer:
        "Choosing FeedxPay for fast and cost-effective international money transfers is a smart decision. We offer competitive exchange rates and low transfer fees, ensuring that you get the best value for your transactions. Our platform guarantees quick and secure delivery of funds, providing you with peace of mind when sending money globally.",
    },
    {
      id: 5,
      text: "What currencies are supported in FeedxPay's multi-currency accounts?",
      answer:
        "FeedxPay's multi-currency accounts support various major currencies, including NGN, USD, EUR, GBP, JPY, AUD, and more. You can conveniently transact in these currencies, making it easy to conduct business and manage finances across different countries.",
    },
  ];
  const [opens, setOpens] = useState(questions.map(() => false));
  const handleOpener = (index) => {
    setOpens((prevOpens) => {
      const newOpens = prevOpens.map((_, i) =>
        i === index ? !prevOpens[index] : false
      );
      return newOpens;
    });
  };

  return (
    <section className="md:h-[47rem] lg:h-[50rem] xl:h-[59rem]">
      <div className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto flex flex-col sm:flex-row sm:justify-between">
        <div>
          <h2 className="text-[#1D1D1F] pt-[3.13rem] text-[1.375rem] leading-[2.1875rem] sm:pt-[14.62rem] md:text-[1.85rem] lg:text-[2.575rem] xl:text-[2.875rem] font-bold md:leading-[3rem] lg:leading-[3.875rem] tracking-[-0.0575rem]">
            Frequently asked
            <br className="hidden sm:block" />
            <span className="text-[#017A59]"> questions</span>
          </h2>
          <p className="pt-[1rem] text-sm leading-normal text-[#8A9099] md:text-xs lg:text-[1.175rem] xl:text-[1.375rem] font-medium md:leading-[2.125rem]">
            We provided answer to some the questions
            <br className="hidden sm:block" /> you would like to ask us. If your
            question is <br className="hidden sm:block" />
            not answered here, kindly contact sales.
          </p>

          <div className="flex gap-[2rem] md:mt-[4rem] mt-[2.5rem] lg:mt-[5rem]">
            <Link to="/contactSales">
              <button className="text-[#017A59] text-sm w-[7.1875rem] h-[2.5rem] md:w-[7.875rem] md:h-[3rem] md:text-sm xl:text-[1.125rem] lg:text-base font-semibold leading-normal tracking-[-0.0225rem] xl:w-[12.875rem] lg:w-[9.875rem] xl:h-[3.6875rem] lg:h-[3rem] rounded-lg border-2 border-[#017A59]">
                Contact Sales
              </button>
            </Link>
            <Link to="/faq">
              <button className="text-white text-sm w-[7.1875rem] h-[2.5rem] md:w-[7.875rem] md:h-[3rem] md:text-sm xl:text-[1.125rem] lg:text-base font-semibold leading-normal tracking-[-0.0225rem] xl:w-[12.875rem] lg:w-[9.875rem] xl:h-[3.6875rem] lg:h-[3rem] rounded-lg bg-[#017A59]">
                FAQs
              </button>
            </Link>
          </div>
        </div>
        <div className="lg:pt-[7.25rem] md:pt-[8.25rem] pt-[2.81rem] space-y-[1.5rem] sm:space-y-[2rem] pb-[4.38rem]">
          {questions.map((quest, index) => {
            return (
              <div key={quest.id}>
                <Card
                  // onClick={() => {
                  //   handleOpener(index);
                  // }}
                  className="mac:w-[45.875rem] flex justify-between items-center w-[21.375rem] h-[4.56rem] md:w-[23.575rem] pro:w-[27.875rem] xl:w-[40.875rem] lg:w-[30.875rem] rounded-lg lg:h-[5rem] xl:h-[6.5rem] mx-auto bg-white shade p-[0.85rem] xl:p-[1.5rem]"
                >
                  <div className="">
                    <h5
                      onClick={() => {
                        handleOpener(index);
                      }}
                      className={`text-[#017A59] w-[40rem] text-sm md:text-sm cursor-pointer xl:text-[1.175rem] mac:text-[1.375rem] lg:text-base font-medium leading-normal lg:leading-[1.875rem] tracking-[-0.0275rem] ${
                        index === 0 ? "xl:w-[36.68rem]" : "w-full"
                      } ${index === 1 ? "xl:w-[33.875rem]" : "w-full"}  ${
                        index === 3 ? "mac:w-[41.875rem]" : "w-full"
                      } ${index === 2 ? "xl:w-[35.875rem]" : "w-full"} ${
                        index === 3 ? "xl:w-[35.875rem]" : "w-full"
                      } 
                      `}
                    >
                      {quest.text}
                    </h5>
                  </div>
                  <div className="flex justify-end">
                    {opens[index] ? (
                      <IoIosArrowUp
                        className="md:text-2xl text-xl cursor-pointer"
                        onClick={() => {
                          handleOpener(index);
                        }}
                      />
                    ) : (
                      <IoIosArrowDown
                        className="md:text-2xl text-xl cursor-pointer"
                        onClick={() => {
                          handleOpener(index);
                        }}
                      />
                    )}
                  </div>
                </Card>
                {opens[index] && (
                  <Card className="mac:w-[45.875rem] md:w-[24.575rem] pro:w-[27.875rem] xl:w-[40.875rem] lg:w-[30.875rem] w-full [7.625rem] lg:h-[9rem] xl:h-[9.2rem] mac:h-[9rem] mt-2 transition-all duration-300 ease-in-out  bg-white rounded-xl shadow-lg p-[0.85rem] xl:p-[1.5rem] flex justify-start items-center">
                    <p className="text-[#1D1D1F] md:text-sm text-[11px] lg:text-[13px] xl:text-base font-medium leading-[1.875rem] tracking-[ -0.0275rem]">
                      {quest.answer}
                    </p>
                  </Card>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Questions;
