import React, { useState } from "react";
import Card from "../../../UI/Card";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const Frequent = () => {
  const Interests = [
    {
      id: 1,
      question: "What kind of investments do I get from Feedxpay?",
      text: "Absolutely! FeedxPay prioritizes the security of your funds. We use advanced encryption and security measures to protect your money and personal information.",
    },
    {
      id: 2,
      question: "How can I withdraw my investments?",
      text: "Absolutely! FeedxPay prioritizes the security of your funds. We use advanced encryption and security measures to protect your money and personal information.",
    },
    {
      id: 3,
      question: "How is Feedxpay investments regulated?",
      text: "Absolutely! FeedxPay prioritizes the security of your funds. We use advanced encryption and security measures to protect your money and personal information.",
    },
    {
      id: 4,
      question:
        "What security measures are in place to protect my money and investments?",
      text: "Absolutely! FeedxPay prioritizes the security of your funds. We use advanced encryption and security measures to protect your money and personal information.",
    },
    {
      id: 5,
      question: "What is the process for closing my investments?",
      text: "Absolutely! FeedxPay prioritizes the security of your funds. We use advanced encryption and security measures to protect your money and personal information.",
    },
  ];

  const [interest, setInterest] = useState(Interests.map(() => false));

  const handleInterest = (index) => {
    setInterest((prevInterest) => {
      const newInterest = prevInterest.map((_, i) =>
        i === index ? !prevInterest[index] : false
      );
      return newInterest;
    });
  };
  return (
    <section
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[59rem] mx-auto flex flex-col sm:flex-row sm:justify-between"
    >
      <div className="sm:pt-[13.56rem] pt-[7.5rem]">
        <h1 className="lg:text-[2.475rem] text-[1.375rem] leading-[2.1875rem] md:text-2xl xl:text-[2.875rem] font-bold lg:leading-[3.875rem] tracking-[-0.0575rem]">
          Frequently asked <br className="hidden sm:block" />
          <span className="text-[#017A59]">questions</span>
          <br />
        </h1>
        <p className="text-[#8A9099] text-sm leading-normal pt-[0.75rem] sm:pt-[1rem]  md:text-xs lg:text-base xl:text-[1.275rem] font-medium lg:leading-[2.125rem]">
          We provided answer to some the questions
          <br className="hidden sm:block" />
          you would like to ask us. If your question is
          <br className="hidden sm:block" />
          not answered here, kindly contact sales.
        </p>
        <Link to="/individual/contactSale">
          <button className="bg-[#017A59] mt-[2.5rem] font-medium text-base leading-normal sm:mt-[4.88rem] text-white sm:leading-[2rem] text-[1.275rem] px-5 py-[10px] rounded-xl">
            Contact sales
          </button>
        </Link>
      </div>
      <div className="flex flex-col space-y-[1.5rem] sm:space-y-[2.45rem] pt-[2.5rem] lg:pt-[6.25rem] md:pt-[3.25rem] md:pb-[5rem] lg:pb-[7rem] pb-[4.37rem]">
        {Interests.map((item, index) => (
          <div key={item.id}>
            <Card className="xl:w-[42.3125rem] w-[21.375rem] h-[4rem] md:w-[25.3125rem] lg:w-[30.3125rem] cursor-pointer sm:h-[5.75rem]  rounded-xl shadow-lg px-[1.52rem] py-[1rem] flex justify-start items-center">
              {" "}
              <h4
                onClick={() => {
                  handleInterest(index);
                }}
                className="text-[#017A59] w-full md:text-sm text-xs md:w-full lg:w-[37rem] lg:text-[1.175rem] font-semibold md:leading-normal lg:leading-[2rem]"
              >
                {item.question}
              </h4>
              {interest[index] ? (
                <IoIosArrowUp
                  className="lg:text-2xl text-sm cursor-pointer"
                  onClick={() => {
                    handleInterest(index);
                  }}
                />
              ) : (
                <IoIosArrowDown
                  className="lg:text-2xl text-sm cursor-pointer"
                  onClick={() => {
                    handleInterest(index);
                  }}
                />
              )}
            </Card>
            {interest[index] && (
              <Card className="xl:w-[42.3125rem] text-xs md:w-[25.3125rem] lg:w-[30.3125rem] h-[5.75rem] md:text-sm lg:text-base mt-2  transition-all duration-300 font-medium ease-in-out rounded-xl shadow-lg px-[1.52rem] py-[1rem] md:h-[5.95rem] flex justify-start items-center">
                {item.text}
              </Card>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Frequent;
