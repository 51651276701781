import React from "react";
import Payment from "./Payment";
import Patners from "./Patners";
// import Payment2 from "./Payment2";

const Part = () => {
  return (
    <section className="">
      <div className="mac:max-w-[83rem] xl:max-w-[75rem] lg:max-w-[60rem] pro:max-w-[52rem] max-w-[21.5rem] md:max-w-[45rem] m-auto">
        <h1 className="sm:text-[2.785rem] sm:text-left text-center text-[1.375rem] text-[#1D1D1F] font-bold leading-[2.1875rem] sm:leading-[3.875rem] tracking-[-0.0575rem]">
          What you stand to <span className="text-[#017A59]">enjoy</span>
          <br /> being a part of us
        </h1>
        <Payment />
      </div>
      <div>{/* <Payment2 /> */}</div>
      <Patners />
    </section>
  );
};

export default Part;
