import React from "react";

const Roles = () => {
  return (
    <section className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[43rem] lg:max-w-[58rem] mx-auto sm:py-[6.25rem] pt-[3.13rem] sm:pt-0 space-y-[2rem] sm:space-y-[3.75rem]">
      <h3 className="text-[#1D1D1F] text-[1.375rem] sm:text-[2.875rem] font-bold leading-[ 3.875rem] tracking-[-0.0575rem]">
        Open Roles
      </h3>
      <p className="text-[ #1D1D1F] text-base sm:text-[1.5rem] font-semibold leading-[2.3125rem] tracking-[-0.03rem]">
        Interns needed. Kindly send CV to Career@feedxpay.com
      </p>

      <p className="text-[#1D1D1F] text-base pb-[4.38rem] sm:pb-0 sm:text-[1.5rem] font-semibold leading-[2.3125rem] tracking-[-0.03rem]">
        Thank you
      </p>
    </section>
  );
};

export default Roles;
