import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-[#017A59] bg-opacity-20 z-40"
      onClick={props.onClose}
    />
  );
};

const Overlay = (props) => {
  return (
    <div className="fixed iphone7:top-[33vh] iphone7:h-[45.5rem] pb-32 iphone12:pb-32 iphone7:pb-96  sm:pb-0 overflow-y-auto lg:overflow-y-hidden top-[24vh] iphone12:top-[24vh] left-1/2 transform md:top-[22.5vh] translate-x-[-50%] lg:top-[28vh] xl:w-[95%] w-[90%] lg:h-[26rem] md:w-[73%] md:h-[29.7rem] lg:w-[95%] h-[45.5rem] xl:h-[28.17rem] bg-white p-0 sm:p-[1rem] rounded-xl shadow-2xl z-40">
      <div>{props.children}</div>
    </div>
  );
};
const portalElements = document.getElementById("overlays");
const BModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElements
      )}
      {ReactDOM.createPortal(
        <Overlay>{props.children}</Overlay>,
        portalElements
      )}
    </>
  );
};

export default BModal;
