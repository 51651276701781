import React, { useState } from "react";
import Nav from "../../NavBar/Nav";
import Pay from "../../../../Assets/Pay.png";
import pngwing1 from "../../../../Assets/pngwing1.png";
import pngwing2 from "../../../../Assets/pngwing2.png";
// import Modal from "../../../UI/Modal";
import Products from "../../NavBar/Products";
import Support from "../../NavBar/Support";
import Company from "../../NavBar/Company";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleModal = () => {
    setOpenModals(true);
  };

  const handleSupportModal = () => {
    setSupportModal(true);
  };

  return (
    <>
      <Nav
        onClick={openModal}
        onOpen={handleModal}
        onSet={handleSupportModal}
      />
      {modal && <Products onHideModal={() => setModal(false)} />}
      {openModals && <Company onHideModals={() => setOpenModals(false)} />}
      {supportModal && (
        <Support onHideSupportModal={() => setSupportModal(false)} />
      )}
      <header className=" background xl:h-[61.5625rem] lg:h-[63.5625rem] md:h-[48.5625rem]">
        <div
          className="flex flex-col-reverse sm:flex-row sm:justify-around mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem]
            lg:max-w-[60rem] mx-auto pt-[2.5rem] sm:pt-[9.5rem]"
        >
          <div className="lg:pt-[12.11rem] md:pt-[6.11rem] pt-[3.75rem]">
            <h1 className="text-[#1D1D1F] pb-[0.75rem] sm:pb-[1rem] text-[1.375rem] lg:text-[2.175rem] xl:text-[2.875rem] md:text-[1.7rem] font-bold leading-[2.1875rem] sm:leading-[3.875rem] tracking-[-0.0575rem]">
              Make <span className="text-[#017A59]">payments</span> across{" "}
              <br />
              borders seemlessly.
            </h1>
            <p className="text-[#8A9099] text-base sm:text-[1.0375rem] xl:text-[1.375rem] font-medium leading-[2.125rem]">
              Send money locally and internationally to <br />
              any country. It is quick and easy.
            </p>
            <div className="flex pt-[2rem] pb-[2.62rem] sm:pb-0 sm:pt-[3rem] lg:pt-[6rem] gap-[1rem] sm:gap-[2rem]">
              <img
                src={pngwing1}
                alt=""
                className="object-contain lg:w-[11.3125rem] md:w-[7.3125rem] xl:w-[14.375rem] h-[5.375rem] w-[7.3125rem]"
              />
              <img
                src={pngwing2}
                alt=""
                className="xl:w-[14.3125rem] lg:w-[11.3125rem] md:w-[7.3125rem] object-contain w-[7.3125rem] h-[4.25rem] mt-[0.56rem]"
              />
            </div>
          </div>
          <div className="sm:pt-[3.94rem] pt-[8.74rem]">
            <img
              src={Pay}
              alt="Pay"
              className="mac:w-[49.3125rem] w-[21.375rem] h-[17.4375rem] xl:w-[45.3125rem] lg:w-[40.3125rem] md:w-[28.3125rem] md:h-[28.3125rem] lg:h-[42.0625rem]"
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
