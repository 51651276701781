import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import Logo2 from "../../../Assets/Logo2.png";
import Subscribe from "./Subscribe";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="sm:h-[70.875rem] h-full bg-[#017A59] ">
        <div className="mac:max-w-[83rem] xl:max-w-[75rem] lg:max-w-[60rem] md:max-w-[45rem] max-w-[21.5rem] pro:space-x-[5rem] md:space-x-[5rem] pro:max-w-[52rem] mx-auto flex flex-col sm:flex-row xl:space-x-[6rem] lg:space-x-[2rem] pt-[6.25rem] text-white">
          <div className="">
            <img
              src={Logo2}
              alt=""
              className="object-cover md:w-[9.85rem] md:h-[1.5rem] lg:w-[11.25rem]  lg:h-[2rem] pro:h-[1.8rem] pro:w-[11.25rem] xl:w-[14.25rem] xl:h-[2.5rem]"
            />
            <div className="text-3xl flex md:gap-5 gap-[1.72rem] lg:gap-[1.7rem] xl:gap-[2.25rem] py-[2.25rem] xl:mr-5">
              <FaFacebookF className="text-white xl:text-3xl md:text-2xl lg:text-2xl text-4xl" />
              <a href="https://www.linkedin.com/company/feedxpay/">
                <FaLinkedinIn className="text-white xl:text-3xl md:text-2xl lg:text-2xl text-4xl" />
              </a>
              <a href="https://twitter.com/feedxpay?s=21&t=9UbgU9s2lOoKwSNXKIkytA">
                <FaXTwitter className="text-white xl:text-3xl md:text-2xl lg:text-2xl text-4xl" />
              </a>
              <a href="https://www.instagram.com/feedxpay/?igshid=MzRlODBiNWFlZA%3D%3D">
                <IoLogoInstagram className="text-white xl:text-3xl md:text-2xl lg:text-2xl text-4xl" />
              </a>
            </div>
          </div>

          <div className="grid md:grid-cols-3 capitalize grid-cols-3 space-x-[0.9rem] lg:grid-cols-4  xl:space-x-[5rem] mac:space-x-[7rem] lg:space-x-[2rem] pro:space-x-[2.5rem] pt-10 sm:pt-0">
            <div className="space-y-[1rem] sm:space-y-[1.5rem] xl:ml-16 mac:ml-20 pro:ml-10 pl-3">
              <p className="text-[1rem] sm:text-[1.375rem] font-semibold from leading-[2rem]">
                Company
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/individual/aboutus"> About US</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <a
                  href="https://medium.com/@feedxpay"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Blog
                </a>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/individual/career">Careers</Link>
              </p>
            </div>
            <div className="space-y-[1rem] sm:space-y-[1.5rem]">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Products
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/payment"> Make Savings</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-sm font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/investments"> Investments</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-sm font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/virtualcards">Cards</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-sm font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/savings"> Savings </Link>
              </p>
            </div>
            <div className="space-y-[1rem] sm:space-y-[1.5rem] w-[9.5rem] sm:w-full">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Support
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/individual/contactSale">contact sales</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/individual/faqs">FAQs</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <a
                  href="https://t.me/feedxpay"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Community
                </a>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/individual/partners"> Become Patners</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/individual/support">support</Link>
              </p>
            </div>
            <div className="space-y-[1rem] sm:space-y-[1.5rem]">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Legal
              </p>
              <p className="sm:text-[1.0625rem] text-xs font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/policy">Terms Use </Link>
              </p>
              <p className="sm:text-[1.0625rem] text-xs font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/policy"> Policies</Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <Subscribe />
      </footer>
    </>
  );
};

export default Footer;
