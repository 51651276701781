import React, { useState } from "react";
import Nav from "../../NavBar/Nav";
import Partner from "../../../../Assets/Partner.png";
import Support from "../../NavBar/Support";
import Company from "../../NavBar/Company";
import Products from "../../NavBar/Products";
import { HashLink as HashRouterLink } from "react-router-hash-link";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleModal = () => {
    setOpenModals(true);
  };

  const handleSupportModal = () => {
    setSupportModal(true);
  };

  return (
    <>
      <Nav
        onClick={openModal}
        onOpen={handleModal}
        onSet={handleSupportModal}
      />
      {modal && <Products onHideModal={() => setModal(false)} />}
      {openModals && <Company onHideModals={() => setOpenModals(false)} />}
      {supportModal && (
        <Support onHideSupportModal={() => setSupportModal(false)} />
      )}
      <section className="relative">
        <header className="pt-[2.5rem] relative">
          <img
            src={Partner}
            alt=""
            className="sm:w-full lg:h-[45.625rem] xl:h-[50.625rem] md:h-[32.625rem] h-[28.9375rem] w-[29.375rem]"
          />
          <div className="overlay absolute top-0 left-0 w-full h-full bg-[#b8d4c6] bg-opacity-60 sm:bg-opacity-50"></div>
          <div className="text-overlay absolute top-1/2 left-1/2 transform -translate-x-1/2 mac:-translate-y-1/2 -translate-y-[38%] xl:-translate-y-[40%] md:-translate-y-[35%] lg:-translate-y-[40%] text-center pt-[6.25rem]">
            <h1 className="text-[#1D1D1F] whitespace-nowrap md:text-[1.5rem] text-[1.3257rem]  xl:text-[2.4875rem] lg:text-[2.05rem] mac:text-[2.875rem] font-bold sm:font-extrabold lg:leading-[3.375rem] xl:leading-[4.375rem]">
              <span className="text-[#017A59]"> Partner</span> with Feedxpay
              today <br />
              and increase your revenue
            </h1>
            <p className="lg:pt-[2.5rem] px-1 sm:px-0 whitespace-pre-wrap pt-[1rem] md:pt-[1.5rem] text-[#1D1D1F] lg:text-[1.125rem] text-base md:text-sm xl:text-[1.5rem] font-medium leading-[1.5rem] lg:leading-[2.3125rem] tracking-[-0.03rem]">
              Collaborate with us today and start generating more{" "}
              <br className="hidden sm:block" />
              revenue, add value and expand your business.
            </p>
            <HashRouterLink smooth to="#section2">
              <button className="lg:mt-[4.56rem] md:mt-[2.56rem] mt-[2.81rem] text-base sm:px-7 px-4 py-3 rounded-lg bg-[#017A59] text-white sm:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
                Become a Partner
              </button>
            </HashRouterLink>
          </div>{" "}
        </header>
      </section>
    </>
  );
};

export default Header;
