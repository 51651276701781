import React, { useState } from "react";
import Nav from "../../NavBar/Nav";
import Invests from "../../../../Assets/Invests.png";
import pngwing1 from "../../../../Assets/pngwing1.png";
import pngwing2 from "../../../../Assets/pngwing2.png";
import Products from "../../NavBar/Products";
import Company from "../../NavBar/Company";
import Support from "../../NavBar/Support";

const Header4 = () => {
  const [modal, setModal] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleModal = () => {
    setOpenModals(true);
  };

  const handleSupportModal = () => {
    setSupportModal(true);
  };

  return (
    <>
      <Nav
        onClick={openModal}
        onOpen={handleModal}
        onSet={handleSupportModal}
      />
      {modal && <Products onHideModal={() => setModal(false)} />}
      {openModals && <Company onHideModals={() => setOpenModals(false)} />}
      {supportModal && (
        <Support onHideSupportModal={() => setSupportModal(false)} />
      )}

      <section className="background xl:h-[65.06rem] lg:h-[55.06rem] md:h-[39.06rem] h-[60.06rem]">
        <header
          className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
            lg:max-w-[60rem] mx-auto flex flex-col-reverse sm:flex-row sm:justify-between lg:pt-[7.5rem] xl:pt-[9.5rem]"
        >
          <div>
            <h1 className="text-[#1D1D1F] pt-[3.25rem] text-[1.375rem] leading-[2.1875rem] xl:pt-[13.81rem] md:pt-[14.81rem] md:text-[1.5175rem] lg:text-[2.175rem] xl:text-[2.875rem] font-bold md:leading-normal lg:leading-[3.875rem] tracking-[-0.0575rem]">
              Invest Smart
              <br />
              Safeguard your Future <br />
              <span className="text-[#017A59]">Invest TODAY!</span>
            </h1>

            <p className="text-[#8A9099] pt-[1rem] text-lg leading-normal md:text-xs lg:text-[1rem] xl:text-[1.375rem] font-medium md:leading-[1.725rem] lg:leading-[2.125rem]">
              All seasons are not the same. Save now and start{" "}
              <br className="hidden sm:block" />
              enjoying financial independence.
            </p>
            <div className="flex xl:pt-[6rem] lg:pt-[4rem] gap-[2rem] md:pt-[2rem] pt-[2.5rem]">
              <img
                src={pngwing1}
                alt=""
                className="object-contain md:w-[7.375rem] w-[7.375rem] lg:w-[10.375rem] xl:w-[14.375rem] h-[5.375rem]"
              />
              <img
                src={pngwing2}
                alt=""
                className="xl:w-[14.3125rem] md:w-[7.375rem] w-[7.375rem] lg:w-[10.375rem] object-contain h-[4.25rem] mt-[0.56rem]"
              />
            </div>
          </div>
          <div className="xl:pt-[5.55rem] lg:pt-[9.55rem] md:pt-[11.55rem] pt-[12.55rem]">
            <img
              src={Invests}
              alt=""
              className="object-cover mac:w-[44.1875rem] md:w-[23.1875rem] md:h-[23.1875rem] lg:w-[32.1875rem] xl:w-[38.1875rem] lg:h-[33.1875rem] xl:h-[44.3125rem]"
            />
          </div>
        </header>
      </section>
    </>
  );
};

export default Header4;
