import React from "react";
import Card from "../../../UI/Card";
import customer from "../../../../Assets/customer.png";

const Main = () => {
  const grow = [
    {
      id: 1,
      num: "01",
      name: "Customer centricity",
      text: "At the core of our company ethos is our customers. We go beyond meeting expectations; we anticipate needs and exceed them. Join a team dedicated to building lasting relationships with our valued customers.",
    },
    {
      id: 2,
      num: "02",
      name: "OwnerShip",
      text: "Empowerment defines our culture. We believe in fostering a sense of ownership in every team member. Take charge of your work, embrace challenges, and contribute to the collective success of our company.",
    },
    {
      id: 3,
      num: "03",
      name: "Clear Communication",
      text: "Communication is the backbone of our collaborative environment. Join a team where transparency and clarity reign supreme. Your ideas and insights are valued, and effective communication is not just encouraged, it's celebrated.",
    },
    {
      id: 4,
      num: "04",
      name: "Innovation",
      text: "Embrace a culture that thrives on innovation. We are not just problem solvers; we are pioneers of change. Join us in pushing the boundaries of what's possible. Your creativity and fresh perspectives will be a driving force behind our continuous evolution and success.",
    },
    {
      id: 5,
      num: "05",
      name: "Adaptability and Flexibility",
      text: " In a dynamic world, adaptability is our strength. We value flexibility in approach, mindset, and work style. Join a team that embraces change as an opportunity, where your ability to adapt is recognized and celebrated.",
    },
    {
      id: 6,
      num: "06",
      name: "Obsession with high quality and excellence",
      text: " Excellence is not just a goal; it's a standard we uphold with unwavering commitment. Join a team that shares an obsession with delivering high-quality results. From the smallest task to the most significant project, excellence is the benchmark always.",
    },
  ];
  return (
    <section>
      <main className="sm:pt-[6.25rem] pt-[3.13rem] mac:max-w-[75rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[72.5rem] md:max-w-[42rem] lg:max-w-[57rem] mx-auto">
        <h1 className="text-center text-[1.375rem] text-[#1D1D1F] md:text-[2.275rem] lg:text-[2.875rem] font-bold leading-[3.875rem] tracking-[-0.0575rem] pb-[0.5rem] sm:pb-[1.8rem]">
          We <span className="text-[#017A59]">help each other</span> grow
        </h1>

        <p className="text-center text-base text-[#8A9099] md:pb-[5.69rem] leading-normal lg:pb-[8.69rem] pb-[2.5rem] sm:text-[#1D1D1F] md:text-base lg:text-[1.375rem] font-medium lg:leading-[1.875rem] tracking-[-0.0275rem]">
          We continuously seek truth, learn and improve in areas of{" "}
          <br className="hidden sm:block" />
          making important decision that as to do with work, relationship,
          <br className="hidden sm:block" />
          customer centricity and everything that is critical to our business.
          <br className="hidden sm:block" />
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:gap-[3.05rem] gap-[2rem] lg:gap-[2.05rem] md:gap-[1.85rem] pro:gap-[2.5rem] pro:justify-center place-content-center pb-[7rem]">
          {grow.map((call) => (
            <Card
              className="mac:w-[36rem] md:h-[19.975rem] lg:h-[23.975rem] w-[342px] h-[259px] md:w-[20.5rem] pro:w-[24rem] xl:w-[35rem] lg:w-[28rem] rounded-lg shade p-[1.25rem] sm:p-7"
              key={call.id}
            >
              {/* <Card className="sm:w-[4rem] w-[2.5rem] h-[2.5rem] sm:h-[4rem] rounded-lg bg-[#F5F5F7]">
                <div className="flex py-4 justify-center">
                  <img
                    src={call.image}
                    alt=""
                    className="object-cover sm:w-[1.54219rem] sm:h-[1.74219rem] w-[1.2rem] h-[1.3rem]"
                  />
                </div>
              </Card> */}
              <div>
                <h2 className="text-[#017A5966] md:text-[30px] font-bold text-[22px]  lg:text-[46px] leading-[35px] md:leading-normal lg:leading-[62px]">
                  {call.num}
                </h2>
              </div>
              <h2 className="pro:text-lg text-base leading-[19.5px] md:leading-normal lg:text-xl pt-[0.8rem] md:pt-[1.5rem] pb-[0.75rem] text-[#017A59] md:text-base xl:text-[1.375rem] font-semibold sm:leading-[2rem]">
                {call.name}
              </h2>

              <p className="text-[#1D1D1F] pro:text-sm text-[0.8rem] leading-[22px] md:leading-normal pro:leading-[2rem] md:text-[0.85rem] lg:text-[1rem] xl:text-[1.375rem] font-medium  lg:leading-[2.125rem]">
                {call.text}
              </p>
            </Card>
          ))}
        </div>
      </main>
    </section>
  );
};

export default Main;
