import React from "react";
import Modal from "../../UI/Modal";
import { Link } from "react-router-dom";

const Products = (props) => {
  const Products = [
    {
      id: 1,
      name: "Make Payments",
      text: "Simplified structures for local and international payments.",
      route: "payment",
    },
    {
      id: 2,
      name: "Virtual Cards",
      text: "Easy online payments and subscriptions with our virtual cards",
      route: "virtualcards",
    },

    {
      id: 3,
      name: "Savings",
      text: "Save for the rainy days as well as your future projects.",
      route: "savings",
    },
    {
      id: 4,
      name: "Investments",
      text: "Make your money work for you and receive generous return on investment(s).",
      route: "investments",
    },
  ];

  return (
    <Modal onClose={props.onHideModal}>
      <div className="py-[0.3rem] px-[0.3rem]">
        {Products.map((data) => (
          <Link to={`/${data.route.toLowerCase()}`} key={data.id}>
            <div className="bg-text py-2 sm:py-4 px-2 ">
              <h1 className="pb-[0.2rem] text-[#1D1D1F] text-lg sm:text-[1.375rem] font-semibold leading-normal lg:leading-[2rem]">
                {data.name}
              </h1>
              <p className="text-sm sm:text-[1.125rem] pb-[0.85rem] sm:pb-0 font-medium leading-[1.5rem] text-[#1D1D1F]">
                {data.text}
              </p>
            </div>{" "}
          </Link>
        ))}
      </div>
    </Modal>
  );
};

export default Products;
