import React, { useState } from "react";
import Nav from "../../../Nav";
import About from "../../../../../Assets/About.png";
// import Products from "../../NavBar/Products";
// import Company from "../../NavBar/Company";
// import Support from "../../NavBar/Support";
import Company from "../../Company";
import Solutions from "../../Solutions";
import Support from "../../Support";

const Header = () => {
  const [modals, setModals] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [supportsModal, setSupportsModal] = useState(false);

  const openModal = () => {
    setModals(true);
  };
  const closeModal = () => {
    setModals(false);
  };

  const handleModal = () => {
    setShowModals(true);
  };
  const handleCloseModal = () => {
    setShowModals(false);
  };

  const handleSupportModal = () => {
    setSupportsModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportsModal(false);
  };

  return (
    <>
      <Nav
        onClicks={openModal}
        onOpens={handleModal}
        onSets={handleSupportModal}
      />
      {modals && <Solutions onHideMode={closeModal} />}
      {showModals && <Company onHideModal={handleCloseModal} />}
      {supportsModal && (
        <Support onHideSupportsModal={handleCloseSupportModal} />
      )}
      <section className="background lg:h-[54.06rem] md:h-[40rem] h-[61.5rem]">
        <header className="xl:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem]  md:max-w-[45rem] lg:max-w-[60rem] mx-auto flex flex-col sm:flex-row sm:justify-around pt-[9.5rem]">
          <div>
            <h1 className="text-[#017A59] uppercase lg:pt-[7.87rem] md:pt-[5.27rem] pt-[2.2rem] text-sm sm:text-[1.5rem] font-semibold leading-[2.3125rem] tracking-[-0.03rem]">
              About us
            </h1>
            <h2 className="text-[#1D1D1F] text-[1.125rem] font-bold lg:text-[1.7rem] xl:text-[2rem] md:leading-normal lg:leading-[3rem] pt-[1.37rem] sm:pt-[2rem] pb-[1.5rem]">
              Cross-border payment made <br />
              easily accessible to everyone
            </h2>
            <p className="text-[#1D1D1F] pt-[1.5rem] sm:pt-[0.8rem] text-base md:text-xs lg:text-base xl:text-[1.375rem] font-medium leading-[1.75rem] md:leading-normal lg:leading-[2.125rem]">
              We are on a mission to revolutionize global finance.
              <br className="hidden sm:block" /> With a commitment to
              innovation, efficiency, and <br className="hidden sm:block" />
              compliance, we empower individuals and businesses{" "}
              <br className="hidden sm:block" />
              to thrive in an interconnected world.
              <br />
            </p>
            <p className="lg:pt-3 xl:pt-5 text-[#1D1D1F] lg:text-base text-base md:text-xs xl:text-[1.375rem] leading-[1.75rem] md:leading-normal font-medium lg:leading-[2.125rem]">
              Our team of experts are dedicated to providing{" "}
              <br className="hidden sm:block" /> tailored solutions that drive
              growth and success.
              <br className="hidden sm:block" />
              Partner with FeedXPay and transcend boundaries in{" "}
              <br className="hidden sm:block" /> the world of finance.
            </p>
          </div>
          <div className="lg:pt-[9.25rem] xl:pt-[6.25rem] pt-[2.5rem] md:pt-[7.25rem]">
            <img
              src={About}
              alt=""
              className="object-contain xl:w-[34.125rem] xl:h-[38.125rem] md:w-[21.125rem] md:h-[21.125rem] lg:w-[28.125rem] lg:h-[28.125rem]"
            />
          </div>
        </header>
      </section>
    </>
  );
};

export default Header;
