import React from "react";
import T from "../../../../Assets/T.png";
import $ from "../../../../Assets/$.png";
import Netflix from "../../../../Assets/Netflix.png";
import Estate from "../../../../Assets/Estate.png";
import Income from "../../../../Assets/Income.png";
import Card from "../../../UI/Card";

const Main = () => {
  const stocks = [
    {
      id: 1,
      img: [Netflix, T, $],
      name: "stocks",
      risks: "High Risks",
      text: "Stocks come with a high risk , but provides you with a high reward margin.",
    },
    {
      id: 2,
      img: Estate,
      name: "Real Estate",
      risks: "Medium Risks",
      text: "Real estate is one of the oldest and reliable investment types in existence today with a medium risk margin.",
    },
    {
      id: 3,
      img: Income,
      name: "Fixed Income",
      risks: "Low Risks",
      text: "Choose your preferred savings plan and complete the important informations required.",
    },
  ];
  return (
    <section
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[60rem] mx-auto"
    >
      <h1 className="sm:pt-[6.25rem] pt-[3.13rem] text-[1.375rem] text-[#1D1D1F] leading-[2.1875rem] text-center md:text-[2.575rem] lg:text-[2.875rem] font-bold md:leading-[3.875rem] tracking-[-0.0575rem]">
        There are <span className="text-[#017A59]">options</span> to pick from
      </h1>

      <p className="text-center pt-[0.5rem] leading-normal text-sm md:pb-[4.44rem] pb-[2.5rem] lg:pb-[6.44rem] text-[#8A9099] md:text-[1rem] lg:text-[1.375rem] font-medium sm:leading-[2.125rem]">
        We got you covered, choose from our best investment options
      </p>

      <div className="flex flex-col sm:flex-row sm:justify-between space-y-[1.5rem] sm:space-y-0 pb-[4.14rem] sm:pb-0">
        {stocks.map((item, index) => (
          <Card
            className="mac:w-[25.625rem] w-[21.375rem] h-[14.875rem] xl:w-[23.225rem] md:w-[13.625rem] md:h-[13.625rem] lg:w-[18.625rem] py-[0.5rem] mx-auto lg:h-[18.8125rem] xl:h-[20.8125rem] cursor-pointer hover:shadow-xl hover:border-transparent xl:px-[0.8rem] lg:py-[2rem] md:py-[1rem] border-[1px] transition-all duration-300 ease-in-out rounded-lg hover:bg-[#EBFFF5] hover:bg-opacity-40 border-[#E8E9EB]"
            key={item.id}
          >
            <div className="flex">
              {Array.isArray(item.img) ? (
                item.img.length > 0 &&
                item.img.map((image, subIndex) => (
                  <img
                    key={subIndex}
                    src={image}
                    className="xl:w-[7rem] lg:w-[5rem] lg:h-[5.2rem] md:w-[4rem] md:h-[3rem] xl:h-[6.2rem]"
                    alt={`Step ${item.stocks} - img ${subIndex + 1}`}
                  />
                ))
              ) : (
                <img
                  src={item.img}
                  alt={`Step ${item.stocks} - img`}
                  className={`lg:w-[5rem] md:w-[3rem] md:h-[2.8rem] lg:h-[4.2rem] w-[4rem] h-[3.5rem] ${
                    index === 2 ? "lg:mr-4" : ""
                  }  ${index === 1 ? "lg:ml-4" : ""} ${
                    index === 1 ? "ml-4" : ""
                  } ${index === 2 ? "md:ml-1" : ""} ${
                    index === 2 ? "w-[4.7rem]" : ""
                  }  ${index === 1 ? "md:ml-4" : ""}`}
                />
              )}
            </div>

            <div className="xl:px-7 px-5">
              <h2 className="text-[#1D1D1F] text-base font-bold md:text-[1.25rem] lg:text-[1.5rem] sm:font-semibold leading-[2.3125rem] tracking-[0.03rem]">
                {item.name}
              </h2>
              <p
                className={`sm:pt-[0.19rem] pt-[0.12rem] md:text-base lg:text-[1.375rem] sm:font-medium font-semibold leading-normal lg:leading-[1.875rem] tracking-[-0.0275rem] ${
                  index === 0 ? "text-[#EB7070]" : ""
                } ${index === 1 ? "text-[#CFA200]" : ""} ${
                  index === 2 ? "text-[#017A59]" : ""
                }`}
              >
                {item.risks}
              </p>
              <p className="text-[#1D1D1F] md:pt-[0.5rem] pt-[0.5rem] lg:pt-[1rem] md:text-xs lg:text-base xl:text-[1.175rem] mac:text-[1.375rem] font-medium md:leading-normal mac:leading-[1.875rem] tracking-[-0.0275rem]">
                {item.text}
              </p>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Main;
