import React, { useState } from "react";
// import Cards from "../../../../Assets/Cards.png";
import Machine from "../../../../Assets/Machine.png";
import GreenCard from "../../../../Assets/GreenCard.png";
import Card from "../../../UI/Card";
import wallet from "../../../../Assets/wallet.gif";
import Group1 from "../../../../Assets/Group1.png";
import Group2 from "../../../../Assets/Group2.png";
import Group3 from "../../../../Assets/Group3.png";

// import Card from "../../../../Assets/Card.png";

const Manage = () => {
  const [activeTexts, setActiveTexts] = useState(0);
  const manage = [
    {
      id: 1,
      img: GreenCard,
      img1: Group1,
      title: "Select Card Type",
      text: "Select your preferred card type (Mastercard or Visa)",
    },
    {
      id: 2,
      img: wallet,
      img1: Group2,

      title: "Exchange Currency",
      text: "Exchange your wallet balance to your preferred currency",
    },
    {
      id: 3,
      img: Machine,
      img1: Group3,
      title: "Debit Card Transaction History",
      text: "Get an overview of your transaction history",
    },
  ];
  const handleSections = (index) => {
    setActiveTexts(index);
    console.log("active", index);
  };
  return (
    <section
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[58rem] mx-auto"
    >
      <h2 className="text-[#1D1D1F] lg:pt-[6.25rem] pt-[4.63rem] text-[1.375rem] leading-[2.1785rem] md:pt-[3.25rem] pb-[4.18rem] md:text-[2.275rem] lg:text-[2.875rem] font-bold sm:leading-[3.875rem] tracking-[-0.0575rem]">
        Manage all your <br />
        <span className="text-[#017A59]">Debit Card</span> Activities
      </h2>
      <div className="flex flex-col sm:flex-row sm:justify-between lg:pb-[8.38rem] xl:pb-[15.38rem] md:pb-[5.38rem]">
        <div className=" flex flex-col space-y-[2.5rem]">
          {manage.map((data, index) => (
            <Card
              key={data.id}
              onClick={() => handleSections(index)}
              className={`${
                activeTexts === index ? "bg-green-50/40" : ""
              } className="border-2 transition-all ease-in-out duration-300 border-[#E8E9EB] rounded-lg hover:border-transparent w-[21.075rem] h-[7.0625rem] md:w-[24.875rem] lg:w-[29.875rem] xl:w-[36.875rem] flex items-center  md:h-[6.25rem]  lg:h-[9.25rem] px-[1rem] md:px-[1rem] lg:px-[2rem] py-[0.5rem]" ${
                activeTexts === index ? "border-transparent" : ""
              } border-2 border-[#E8E9EB] p-[1rem] rounded-lg`}
            >
              <div className="flex md:gap-[1rem] gap-[0.75rem] lg:gap-[1.5rem] items-start justify-start">
                <img
                  src={data.img}
                  alt=""
                  className={`${index === 0 ? "md:-mt-2" : ""} ${
                    index === 0 ? "md:pr-2" : ""
                  } ${index === 0 ? "lg:pr-4" : ""} ${
                    index === 1 ? "mt-2 md:mt-1" : ""
                  } ${index === 1 ? "lg:mt-0" : ""} ${
                    index === 2 ? "xl:-mt-2 " : ""
                  } ${index === 2 ? "lg:-mt-1" : ""} ${
                    index === 0 ? "lg:-mt-3" : ""
                  } ${
                    index === 2 ? "md:-mt-1" : ""
                  } object-cover w-[2.25rem] h-[2.0625rem] sm:w-[3.3125rem] sm:h-[3.3125rem]`}
                />
                <div>
                  <h2 className="text-[#017A59] text-sm lg:text-base xl:text-[1.375rem] font-semibold leading-[2rem]">
                    {data.title}
                  </h2>

                  <p
                    className={`lg:pt-[1rem] pt-[0.5rem] text-[#1D1D1F] text-sm leading-normal lg:leading-[1.75rem] md:text-xs lg:text-sm xl:text-[1.25rem] font-medium tracking-[-0.025rem] ${
                      index === 0 ? "sm:w-[20rem]" : ""
                    }
                  index === 0 ? "w-[14rem]" : ""
                  }`}
                  >
                    {data.text}
                  </p>
                </div>
              </div>
            </Card>
          ))}
        </div>
        <div className="pt-[3.13rem] pb-[4.19rem] sm:pb-0 sm:pt-0">
          {activeTexts === 0 ? (
            <img
              src={manage[0].img1}
              alt=""
              className="object-contain w-[21.375rem]  md:w-[22.5625rem] md:h-[28.5625rem] mac:w-[46.5625rem] lg:w-[29.5625rem] xl:w-[39.5625rem] lg:h-[31.6875rem]"
            />
          ) : activeTexts === 1 ? (
            <img
              src={manage[1].img1}
              alt=""
              className="object-contain w-[21.375rem] md:w-[17.5625rem] h-[17.4375rem] md:h-[30.5625rem] mac:w-[46.5625rem] lg:w-[22.5625rem] xl:w-[34.5625rem] lg:h-[31.6875rem]"
            />
          ) : (
            <img
              src={manage[2].img1}
              alt=""
              className="object-contain w-[21.375rem] md:w-[19.5625rem] h-[17.4375rem] md:h-[30.5625rem] mac:w-[46.5625rem] lg:w-[24.5625rem] xl:w-[37.5625rem] lg:h-[31.6875rem]"
            />
          )}
        </div>
      </div>
    </section>
  );
};
export default Manage;
