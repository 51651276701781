import React from "react";

const Card = (props) => {
  const card = " Cards " + props.className;
  return (
    <div className={card} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Card;
