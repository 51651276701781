import React from "react";
import Modal2 from "../../UI/Modal2";
import { Link } from "react-router-dom";

const Company = (props) => {
  const Products = [
    {
      id: 1,
      name: "About Us",
      text: "Know more about the company, our services, our amazing team and lot more",
      route: "/individual/aboutus",
    },
    {
      id: 2,
      name: "Careers",
      text: "Looking to join a team of extraordinary doers? Then Feedxpay is for you ",
      route: "/individual/career",
    },
    {
      id: 3,
      name: "Blog",
      text: "Learning never stops, Get access to insightful resources",
      externalLink: "https://medium.com/@feedxpay",
    },
  ];

  return (
    <Modal2 onClose={props.onHideModals}>
      <div className="py-[0.3rem] px-[0.3rem]">
        {Products.map((data) =>
          data.route ? (
            <Link key={data.id} to={data.route}>
              <div className="bg-text py-2 sm:py-4 px-2">
                <h1 className="pb-[0.2rem] text-[#1D1D1F] text-lg sm:text-[1.375rem] font-semibold leading-normal lg:leading-[2rem]">
                  {data.name}
                </h1>
                <p className="text-sm sm:text-[1.125rem] pb-[0.85rem] sm:pb-0 font-medium leading-[1.5rem] text-[#1D1D1F]">
                  {data.text}
                </p>
              </div>
            </Link>
          ) : (
            <a
              key={data.id}
              href={data.externalLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="bg-text py-2 sm:py-4 px-2">
                <h1 className="pb-[0.2rem] text-[#1D1D1F] text-lg sm:text-[1.375rem] font-semibold leading-normal lg:leading-[2rem]">
                  {data.name}
                </h1>
                <p className="text-sm sm:text-[1.125rem] pb-[0.85rem] sm:pb-0 font-medium leading-[1.5rem] text-[#1D1D1F]">
                  {data.text}
                </p>
              </div>
            </a>
          )
        )}
      </div>
    </Modal2>
  );
};

export default Company;
