import React from "react";
import Card from "../../../UI/Card";
import check2 from "../../../../Assets/check2.gif";
import guard from "../../../../Assets/guard.gif";
import transfer from "../../../../Assets/transfer.gif";
import wallet from "../../../../Assets/wallet.gif";

const Main = () => {
  const Payments = [
    {
      id: 1,
      img: check2,
      name: "Instant transfer",
      text: "Our platform is designed to provide you with the fastest means of  transferring money both locally and internationally in the quickest time possible.",
    },
    {
      id: 2,
      img: transfer,
      name: "Low Tranfer fee",
      text: "Our low transfer fees are designed to offer you significant savings on transactions compared to traditional financial services.",
    },
    {
      id: 3,
      img: wallet,
      name: "Transparent Exchange Rate",
      text: "Access real-time exchange rates that reflect the current market conditions. No hidden charges or mark ups on your currency exchange.",
    },
    {
      id: 4,
      img: guard,
      name: "Safe & Secure Transactions",
      text: "We employ advanced encryption technology to safeguard your personal and financial information, ensuring that it remains confidential and secure.",
    },
  ];
  return (
    <main
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[43rem]
    lg:max-w-[58rem] mx-auto flex flex-col sm:flex-row md:justify-around lg:justify-between"
    >
      <div className="flex flex-col justify-center pt-[3.12rem] sm:pt-[2rem] ">
        <h1 className="mac:text-[2.875rem] xl:text-[2.575rem] text-[1.375rem] leading-[2.1875rem] lg:text-[1.7rem] md:text-[1rem] pb-[0.5rem] font-bold md:leading-normal lg:leading-[3.875rem] tracking-[-0.0575rem]">
          We offer the best <br className="hidden sm:block" />
          <span className="text-[#017A59] ">payment service.</span> You{" "}
          <br className="hidden sm:block" />
          can bank on US
        </h1>
        <p className="text-[#8A9099] lg:text-base xl:text-[1.1375rem] mac:text-[1.375rem] md:text-sm  font-medium leading-normal lg:leading-[2.125rem]">
          Easily send and receive money without <br />
          location barrier.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:gap-[1.88rem] gap-[2rem] sm:gap-[1.7rem] pt-[4rem] lg:pt-[8.19rem] pb-[4.38rem] md:pt-[4.89rem] md:pb-[3.25rem] lg:pb-[6.25rem] place-content-start justify-start text-start items-start">
        {Payments.map((item, index) => (
          <Card
            key={item.id}
            className="xl:w-[23.3125rem] lg:w-[18.3125rem] md:w-[12.3125rem] md:h-[15.3825rem] lg:h-[18.8125rem] relative bg-[#EBFFF5] bg-opacity-40 p-[1.25rem] rounded-xl shadow-md md:p-[1rem] lg:p-[1.82rem]"
          >
            {" "}
            <div className="mb-[0.75rem]">
              <img
                src={item.img}
                alt="gif"
                className={`object-cover w-[4.0625rem] absolute top-[1.5rem] ${
                  index === 0 ? "-left-[0.8rem]" : ""
                } ${index === 1 ? "left-[0.012rem]" : ""} ${
                  index === 0 ? "md:left-[0.1rem]" : ""
                } ${index === 3 ? "md:left-[0.5rem]" : ""} ${
                  index === 1 ? "md:left-[0.15rem]" : ""
                } ${index === 0 ? "lg:left-[0.4rem]" : ""} ${
                  index === 1 ? "lg:left-[0.4rem]" : ""
                } ${index === 3 ? "lg:left-[0.5rem]" : ""} ${
                  index === 2 ? "xl:right-[17.8rem]" : "right-[18.5rem]"
                } ${index === 0 ? "w-[5.0625rem]" : "w-[4.0625rem]"} ${
                  index === 1 ? "w-[4.7625rem]" : "w-[4.0625rem]"
                } ${index === 1 ? "pt-3" : "pt-0"} ${
                  index === 3 ? "left-[0.2rem]" : ""
                } ${index === 3 ? "w-[3rem]" : ""} ${
                  index === 3 ? "left-[0.5rem]" : ""
                } ${index === 2 ? "w-[3rem]" : ""} ${
                  index === 2 ? "lg:w-[4rem]" : ""
                }`}
              />
            </div>
            <h1 className="pb-[1rem] text-[#017A59] md:text-xs pt-[3.7rem] lg:text-[1rem] xl:text-[1.375rem] font-semibold leading-[2rem] ">
              {item.name}
            </h1>
            <p className="text-[#1D1D1F] md:text-xs lg:text-sm mac:text-[1.125rem] xl:text-base font-medium leading-normal lg:leading-[1.5rem]">
              {item.text}
            </p>
          </Card>
        ))}
      </div>
    </main>
  );
};

export default Main;
