import React, { useState } from "react";
import Nav from "../../Nav";
import Careers from "../../../../Assets/Careers.png";
import Careers1 from "../../../../Assets/Careers1.png";
import Company from "../Company";
import Solutions from "../Solutions";
import Support from "../Support";

const Header = () => {
  const [modals, setModals] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [supportsModal, setSupportsModal] = useState(false);

  const openModal = () => {
    setModals(true);
  };
  const closeModal = () => {
    setModals(false);
  };

  const handleModal = () => {
    setShowModals(true);
  };
  const handleCloseModal = () => {
    setShowModals(false);
  };

  const handleSupportModal = () => {
    setSupportsModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportsModal(false);
  };

  return (
    <>
      <Nav
        onClicks={openModal}
        onOpens={handleModal}
        onSets={handleSupportModal}
      />
      {modals && <Solutions onHideMode={closeModal} />}
      {showModals && <Company onHideModal={handleCloseModal} />}
      {supportsModal && (
        <Support onHideSupportsModal={handleCloseSupportModal} />
      )}
      <section className="background lg:h-[65.12rem] md:h-[45.12rem]">
        <header
          className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
            lg:max-w-[60rem] mx-auto flex flex-col sm:flex-row sm:justify-around lg:pt-[9.5rem] md:pt-[6.5rem] pt-[10.5rem]"
        >
          <div className="lg:pt-[7.75rem] md:pt-[6.75rem]">
            <img
              src={Careers1}
              alt=""
              className="mac:w-[21.25rem] w-[11.6875rem] h-[23.75rem] xl:w-[19.25rem] xl:h-[40.25rem] lg:w-[17.25rem] lg:h-[35.25rem] md:w-[11.25rem] md:h-[26.25rem]"
            />
          </div>
          <div className="sm:pt-[6.25rem] pt-[3.13rem]">
            <h2 className="text-[#017A59] uppercase text-center sm:pb-[3.31rem] text-sm pb-[1.38rem] sm:text-[1.5rem] font-semibold leading-normal sm:leading-[2.3125rem] tracking-[-0.03rem]">
              Feedxpay Careers
            </h2>
            <h4 className="text-[#1D1D1F] pl-0 md:pl-2 lg:pl-0 xl:text-[1.5rem] text-[1.125rem] text-center lg:text-[1.175rem] mac:text-[2rem] font-bold leading-normal sm:leading-[3rem] pb-[1.75rem] sm:pb-[2.31rem]">
              Where dreamers and doers thrive
            </h4>
            <div className="flex justify-center flex-col items-center">
              <p className="pb-[2rem] text-[#1D1D1F] hidden sm:block md:text-[11px] xl:text-[1.175rem] lg:text-sm mac:text-[1.375rem] text-base font-medium leading-normal lg:leading-[1.875rem] tracking-[-0.0275rem]">
                Are you ready to be a part of a dynamic team that is
                <br className="hidden sm:block" /> all out to reshape the future
                of financial services?
                <br className="hidden sm:block" />
              </p>
              <p className="pb-[2rem] text-[#1D1D1F] hidden sm:block md:text-[11px] xl:text-[1.175rem] lg:text-sm text-base mac:text-[1.375rem] font-medium leading-normal lg:leading-[1.875rem] tracking-[-0.0275rem]">
                At FeedXPay, we believe in the power of innovation,
                <br className="hidden sm:block" /> collaboration, and a shared
                commitment to <br className="hidden sm:block" /> excellence.
              </p>
              <p className="text-[#1D1D1F] hidden sm:block xl:text-[1.175rem] md:text-[11px] lg:text-sm mac:text-[1.375rem] font-medium leading-normal lg:leading-[1.875rem] tracking-[-0.0275rem] pb-[3.13rem] sm:pb-0">
                Explore exciting career opportunities with us and be
                <br className="hidden sm:block" /> a driving force behind the
                evolution of the financial
                <br className="hidden sm:block" /> landscape.
              </p>

              <p className="text-[#1D1D1F]  block sm:hidden  font-medium leading-normal tracking-[-0.0275rem] pb-[2rem] sm:pb-0">
                We believe economic prosperity is possible. We also believe
                permission-less and successful entrepreneurship in emerging
                markets is possible
              </p>
              <p className="pb-[2rem] text-[#1D1D1F] block sm:hidden text-base  font-medium leading-normal  tracking-[-0.0275rem]">
                Our mission is making economic prosperity accessible to
                everyone, and we are looking for the best and right people that
                can help us accomplish this.
              </p>
              <p className="text-[#1D1D1F] block sm:hidden   font-medium leading-normal tracking-[-0.0275rem] pb-[3.13rem] sm:pb-0">
                We value unique contributions, embrace diversity in people and
                ideas and welcome everyone.
              </p>
            </div>
          </div>
          <div className="lg:pt-[7.75rem] md:pt-[6.75rem] flex justify-end pb-[4.38rem] sm:pb-0">
            <img
              src={Careers}
              alt=""
              className="w-[13.8125rem] h-[20.9375rem] mac:w-[23.25rem] xl:w-[20.25rem] xl:h-[40.25rem] lg:w-[18.25rem] lg:h-[35.25rem] md:w-[13.25rem] md:h-[26.25rem]"
            />
          </div>
        </header>
      </section>
    </>
  );
};

export default Header;
