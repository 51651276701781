import React, { useState } from "react";
import Nav from "../../Nav";
import Card from "../../../UI/Card";
import { IoIosArrowUp } from "react-icons/io";
import { MdArrowForwardIos } from "react-icons/md";
import Solutions from "../Solutions";
import Support from "../Support";
import Company from "../Company";
import { IoIosArrowDown } from "react-icons/io";

const Header = () => {
  const Questions = [
    {
      id: 1,
      question:
        "How does FeedxPay simplify cross-border transactions for businesses?",
      text: "FeedxPay is an innovative Neo bank that offers digital banking services, providing seamless and secure cross-border payments and a range of other financial solutions.",
    },
    {
      id: 2,
      question:
        "What are the benefits of opening a multi-currency account with FeedxPay?",
      text: "Absolutely! FeedxPay prioritizes the security of your funds. We use advanced encryption and security measures to protect your money and personal information.",
    },
    {
      id: 3,
      question:
        "How secure and reliable are online money transfers with FeedxPay?",
      text: "Opening an account with FeedxPay is easy and convenient. Simply visit our website, click on  Sign Up, and follow the simple registration process.",
    },
    {
      id: 4,
      question:
        "Why choose FeedxPay for fast and cost-effective international money transfers?",
      text: "We believe in transparency. FeedxPay has no hidden fees. Our account setup and maintenance are fee-free, ensuring a straightforward banking experience.",
    },
    {
      id: 5,
      question:
        "What currencies are supported in FeedxPay's multi-currency accounts?",
      text: "FeedxPay employs industry-leading security protocols, including two-factor authentication, to safeguard your account and personal data.",
    },
    {
      id: 6,
      question:
        "How can businesses leverage FeedxPay's payment gateway for seamless transactions?",
      text: "Yes, you can link your other bank accounts to your FeedxPay account for easy fund transfers and a comprehensive financial overview.",
    },
    {
      id: 7,
      question:
        "What innovative features does FeedxPay offer for personal finance management?",
      text: "Transfers to and from your FeedxPay account are fast and efficient. Most transactions are completed within one business day.",
    },
    {
      id: 8,
      question:
        "How does blockchain technology enhance security in FeedxPay's cross-border transactions?",
      text: "As a digital neo bank, FeedxPay currently supports electronic transfers. We do not accept cash or check deposits at this time.",
    },
    {
      id: 9,
      question:
        "What sets FeedxPay's digital wallets apart in the fintech market?",
      text: "No, FeedxPay does not have any minimum balance requirements. You can maintain your account with any balance amount that suits you.",
    },
    {
      id: 10,
      question:
        "How can individuals and businesses optimize forex transactions with FeedxPay?",
      text: "Yes, FeedxPay enables you to set up automatic bill payments, ensuring your bills are paid on time with minimal effort.",
    },
    {
      id: 11,
      question:
        "What insurtech solutions are available to users of Feedxpay’s fintech platorm",
      text: "Yes, FeedxPay enables you to set up automatic bill payments, ensuring your bills are paid on time with minimal effort.",
    },
    {
      id: 12,
      question:
        "How can Feedxpay mobile banking app simplify financial tasks on the go?",
      text: "Should you wish to close your account, simply contact our customer support, and they will guide you through the process.",
    },
    {
      id: 13,
      question:
        "Why is FeedxPay's peer-to-peer lending platform a smart choice for borrowers and investors?",
      text: "Our customer support team strives to provide prompt assistance. You can expect a response within one business day or sooner.",
    },
    {
      id: 14,
      question:
        "How does FeedxPay utilize open banking to empower users with financial data?",
      text: "FeedxPay offers various rewards and cashback programs, allowing you to earn perks while using your account for transactions.",
    },
    {
      id: 15,
      question:
        "What are the benefits of using FeedxPay's robo-advisors for investment guidance?",
      text: "FeedxPay provides generous transaction limits, ensuring you can conduct your financial activities without restrictions.",
    },
    {
      id: 16,
      question:
        "How does FeedxPay ensure data privacy and security in its financial technology solutions?",
      text: "Currently, FeedxPay focuses on digital banking and cross-border payments, but we are continuously expanding our services.",
    },
    {
      id: 17,
      question:
        "What sets FeedxPay's contactless payment options apart for convenient and secure transactions?",
      text: "FeedxPay allows payments to a wide range of businesses, making it easy to settle transactions with various merchants.",
    },
    {
      id: 18,
      question:
        "How does FeedxPay's AI technology enhance personalized financial insights for users?",
      text: "Yes, FeedxPay is compatible with mobile payment platforms like Apple Pay and Google Pay for added convenience.",
    },
    {
      id: 19,
      question:
        "What steps does FeedxPay take to provide a smooth user experience in cross-border transactions?",
      text: "If you forget your password or get locked out of your account, you can easily reset your password through the account recovery process.",
    },
    {
      id: 20,
      question:
        "Why choose FeedxPay as your go-to Neo bank for multi-currency needs and international payments?",
      text: "Yes, FeedxPay often offers special promotions and rewards for new customers. Be sure to check our website or app for the latest offers.",
    },
  ];
  const [open, setOpen] = useState(Questions.map(() => false));
  const [modals, setModals] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [supportsModal, setSupportsModal] = useState(false);
  const handleOpen = (index) => {
    setOpen((prevOpen) => {
      const newOpen = prevOpen.map((_, i) =>
        i === index ? !prevOpen[index] : false
      );
      return newOpen;
    });
  };

  const openModal = () => {
    setModals(true);
  };
  const closeModal = () => {
    setModals(false);
  };

  const handleModal = () => {
    setShowModals(true);
  };
  const handleCloseModal = () => {
    setShowModals(false);
  };

  const handleSupportModal = () => {
    setSupportsModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportsModal(false);
  };

  return (
    <>
      <Nav
        onClicks={openModal}
        onOpens={handleModal}
        onSets={handleSupportModal}
      />
      {modals && <Solutions onHideMode={closeModal} />}
      {showModals && <Company onHideModal={handleCloseModal} />}
      {supportsModal && (
        <Support onHideSupportsModal={handleCloseSupportModal} />
      )}
      <section className="background lg:h-[210.81rem]">
        <header className="xl:max-w-[83rem] pro:max-w-[52rem] pro:space-x-[6rem] max-w-[22rem] md:space-x-[2rem] md:max-w-[45rem] lg:max-w-[60rem]  mx-auto flex lg:justify-around flex-col sm:flex-row xl:justify-evenly mt-[8.5rem]">
          <Card className="lg:mt-[19.25rem] mt-[5.95rem] md:mt-[16.25rem] rounded-lg lg:gap-[4.25rem] md:gap-[2.25rem] xl:gap-[6.25rem] xl:w-[17.25rem] md:w-[14.25rem] lg:w-[15.25rem] w-[13.125rem] flex  md:h-[7rem] lg:h-[8rem] h-[3.3rem] shades sm:bg-white sm:px-[2rem] sm:py-[3rem] border-2 border-[#017A59] px-[1rem] py-[0.8rem] sm:border-0">
            <p className="text-[#017A59] text-[1rem] sm:text-[1.375rem] font-semibold leading-normal sm:leading-[2rem]">
              Business{" "}
              <span className="sm:hidden inline-blocks">Questions</span>
            </p>
            <MdArrowForwardIos className="mt-2 hidden sm:block" />
          </Card>
          <div className="flex flex-col lg:pb-[8.31rem] md:pb-[3.38rem] pb-[2.38rem]">
            <h2 className="lg:pt-[6.25rem] -mt-[10rem] sm:-mt-0 pt-[2.35rem] sm:block md:pt-[3.25rem] text-[#017A59] md:text-[2rem] text-[1.375rem] pro:text-[2.3rem] lg:text-[2.575rem] xl:text-[2.875rem] font-bold leading-[3.875rem] tracking-[-0.0575rem] sm:pb-[0.5rem]">
              Frequently asked questions
            </h2>
            <p className="pb-[4.37rem] hidden sm:block text-[#8A9099] lg:text-[1.175rem] md:text-[0.9rem] xl:text-[1.375rem] font-medium leading-[2.125rem]">
              We provided answer to some questions you would like to ask us.
              <br className="hidden lg:block" />
              If your question is not answered here, kindly contact sales.
            </p>
            <span className="md:space-y-[2.45rem] space-y-[1.45rem] mt-[5.85rem] sm:mt-0">
              {Questions.map((quest, index) => (
                <div key={quest.id}>
                  <Card className="xl:w-[45.875rem] lg:w-[38.875rem] w-[21.375rem] md:w-[28.775rem] pro:w-[32.875rem] cursor-pointer h-[5.7rem] sm:h-[6.5rem] bg-white rounded-xl shadow-lg px-[1.52rem] py-[1rem] flex justify-start items-center">
                    {" "}
                    <h4
                      onClick={() => {
                        handleOpen(index);
                      }}
                      className="text-[#017A59] w-[37rem] text-sm lg:text-[1.175rem] font-semibold leading-[1.375rem] sm:leading-[2rem]"
                    >
                      {quest.question}
                    </h4>
                    {open[index] ? (
                      <IoIosArrowUp
                        className="md:text-2xl text-3xl cursor-pointer"
                        onClick={() => {
                          handleOpen(index);
                        }}
                      />
                    ) : (
                      <IoIosArrowDown
                        className="md:text-2xl text-3xl cursor-pointer"
                        onClick={() => {
                          handleOpen(index);
                        }}
                      />
                    )}
                  </Card>
                  {open[index] && (
                    <Card className="xl:w-[45.875rem] lg:w-[38.875rem] md:w-[28.775rem] pro:w-[32.875rem] w-[21.375rem] [7.625rem] mt-2 transition-all font-medium duration-300 ease-in-out  bg-white rounded-xl shadow-lg px-[1.52rem] py-[1rem] flex justify-start items-center">
                      <p className="text-[#1D1D1F] leading-normal md:text-sm text-sm lg:text-[1.275rem] font-medium lg:leading-[1.875rem] tracking-[-0.0275rem]">
                        {quest.text}
                      </p>
                    </Card>
                  )}
                </div>
              ))}
            </span>
            <p className="pt-[2.5rem]  text-[#1D1D1F] md:text-base text-sm lg:text-[1.375rem] font-medium leading-normal sm:leading-[1.875rem] tracking-[-0.0275rem]">
              You can send us an email on{" "}
              <span className="text-[#017A59]">hello@feedxpay.com</span> or call{" "}
              <br className="hidden sm:block" />
              +234 9035279530 between 8:00 am and 5:00 pm on weekdays.{" "}
            </p>
          </div>
        </header>
      </section>
    </>
  );
};

export default Header;
