import Nav from "../../Nav";
import Contact from "../../../../Assets/Contact.png";
import Card from "../../../UI/Card";
import Solutions from "../Solutions";
import Support from "../Support";
import Company from "../Company";
import { useState } from "react";
import { Link } from "react-router-dom";

const Headers = () => {
  const [modals, setModals] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [supportsModal, setSupportsModal] = useState(false);

  const openModal = () => {
    setModals(true);
  };
  const closeModal = () => {
    setModals(false);
  };

  const handleModal = () => {
    setShowModals(true);
  };
  const handleCloseModal = () => {
    setShowModals(false);
  };

  const handleSupportModal = () => {
    setSupportsModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportsModal(false);
  };
  return (
    <>
      <Nav
        onClicks={openModal}
        onOpens={handleModal}
        onSets={handleSupportModal}
      />
      {modals && <Solutions onHideMode={closeModal} />}
      {showModals && <Company onHideModal={handleCloseModal} />}
      {supportsModal && (
        <Support onHideSupportsModal={handleCloseSupportModal} />
      )}
      <section className="relative">
        <header className="sm:pt-[1.5rem] pt-[8.5rem] relative">
          <img
            src={Contact}
            alt=""
            className="sm:w-full w-[45.375rem] h-[18.0625rem] md:h-[39.0625rem] lg:h-[53.0625rem] xl:h-[64.0625rem]"
          />
          <div className="overlay absolute top-0 left-0 w-full h-full bg-[#b8d4c6] bg-opacity-50"></div>
          <div className="text-overlay absolute top-1/2 left-1/2 xl:-translate-y-1/2 md:-translate-y-[30%] translate-y-[10%] lg:-translate-y-[30%] -translate-x-1/2 text-center w-full">
            <h1 className="text-[#1D1D1F] transform lg:text-[2.8875rem] lg:leading-normal md:leading-normal md:text-[1.8875rem] xl:text-[3.1875rem] font-extrabold xl:leading-[4.375rem]">
              We are here to point you <br />
              in the <span className="text-[#017A59]">right directions</span>
            </h1>
            <p className="pt-[1.5rem] text-xs px-[3rem] leading-normal sm:px-0 text-[#1D1D1F] w-full lg:text-[1.2rem] md:text-sm md:leading-normal xl:text-[1.5rem] font-medium lg:leading-[2.3125rem] tracking-[-0.03rem]">
              Need more assistance? Have more questions? We have the an amazing{" "}
              <br className="hidden sm:block" />
              support team who are ready to help you through.
            </p>
            <Link to="/faq">
              <button className="xl:mt-[4.56rem]  hidden sm:block sm:mx-auto md:mt-[2.56rem] sm:px-7 sm:py-3 px-5 py-2 rounded-lg bg-[#017A59] text-white text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
                Read FAQs
              </button>
            </Link>{" "}
          </div>{" "}
          <div className="relative">
            <Card className="absolute xl:-top-[7.5rem] md:-top-[5.5rem] -top-[2.5rem] p-[0.9rem] sm:p-[2.5rem] backdrop-blur-lg left-1/2 transform -translate-x-1/2 lg:w-[48.8125rem] w-[21.375rem] md:w-[38.8125rem] h-[6rem] sm:h-[10.625rem] rounded-lg bg-[#EBFFF5] bg-opacity-50">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <p className=" text-[#017A59] text-xs -mt-1 sm:-mt-1 lg:text-[1.125rem] md:text-sm font-medium leading-[1.5rem]">
                  Telephone:{" "}
                  <span className="text-[#1D1D1F] lg:text-base text-xs md:text-sm">
                    +234 903 527 9530
                  </span>
                </p>
                <p className="text-[#017A59]   text-xs md:text-sm lg:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
                  Support Email:{" "}
                  <span className="text-[#1D1D1F] lg:text-base text-xs md:text-sm">
                    Hello@Feedxpay.com
                  </span>
                </p>
              </div>
              <p className="sm:text-center mt-1 text-left sm:pt-[2rem] text-[#1D1D1F] text-[11px] md:text-[1.15rem] lg:text-[1.375rem] font-medium leading-normal sm:leading-[2.125rem]">
                We are available between{" "}
                <span className="text-[#017A59]">8AM to 5AM</span> on weekdays
              </p>
            </Card>
          </div>
        </header>
      </section>
    </>
  );
};

export default Headers;
