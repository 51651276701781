import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import Wireframe1 from "../../Assets/Business/Wireframe1.png";

const Header = () => {
  return (
    <>
      {/* <Nav /> */}
      <section className="bg lg:h-[60.79rem] xl:h-[70.69rem]">
        <div className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto pt-[9.5rem] sm:pt-[12.9rem]">
          <h2 className="text-left text-[1.275rem] leading-[2.1875rem] md:leading-[3rem] sm:text-center text-[#1D1D1F] md:text-[2.2rem] lg:text-[2.5rem] xl:text-[4rem] font-extrabold lg:leading-[3.5rem] xl:leading-[5rem]">
            Simplifying <span className="text-[#017A59]">B2B payments</span>{" "}
            <br className="hidden sm:block" />
            beyond borders
          </h2>
          <p className="text-left text-base sm:text-center pt-[0.5rem] sm:pt-[1rem] tracking-[-0.03rem] lg:leading-[2.3125rem] leading-normal font-medium md:text-base lg:text-base xl:text-[1.5rem] text-[#8A9099]">
            We make it easy for African businesses to send and receive{" "}
            <br className="hidden sm:block" />
            money globally
          </p>
          <div className="flex justify-start sm:justify-center gap-[2rem] pt-[2.5rem] sm:pt-[3.12rem]">
            <Link to="/contactSales">
              <button className="w-[7.1875rem] h-[2.5rem] text-sm md:w-[12.875rem] md:h-[3.9375rem] text-[#017A59] bg-transparent border-2 border-[#017A59] rounded-lg text-[1.125rem] leading-[1.5rem] tracking-[-0.0225rem] font-semibold shadows2">
                Contact Sales
              </button>
            </Link>
            <a
              href="https://business.feedxpay.com/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="w-[7.1875rem] h-[2.5rem] text-sm md:w-[12.875rem] md:h-[3.9375rem] text-white bg-[#017A59] rounded-lg text-[1.125rem] leading-[1.5rem] tracking-[-0.0225rem] font-semibold shadows">
                Get Started
              </button>
            </a>
          </div>
          <div className="pt-[3.26rem] sm:pt-[4.26rem] flex justify-start md:justify-center">
            <img
              src={Wireframe1}
              alt=""
              className="xl:w-[65.25rem] w-[20.5rem] h-[10.2625rem] md:w-[27.5rem] lg:w-[55.25rem] lg:h-[29.79688rem] xl:h-[30.79688rem]"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
